import React, { useState, useEffect, useContext } from 'react';
import { Legend, BarChart, Bar, ResponsiveContainer, Brush, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { MyContext } from './MyContext';
import Checkbox from '@material-ui/core/Checkbox';
import Loader from 'react-loader-spinner'
import addMonths from 'date-fns/addMonths'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import * as Comlink from 'comlink';
import parse from 'date-fns/parse'
import { useHistory } from 'react-router-dom'; //when updating to v6 then useNavigate will be used
import useGraphLegend from './useGraphLegend';

//import remoteFunction from './MyWorker';

// const worker = new Worker("../public/MyWorker");
// console.log("removeFunction called1");
const MyClass = Comlink.wrap(new Worker("../public/MyWorker.js"));


const UptimeAnalyticsDaily = () => {
    const { uptimeData, selectedMachines, analyticsDailyDataShort,
        setAnalyticsDailyDataShort, analyticsDailyDataLong, setAnalyticsDailyDataLong,
        setArchiveDate, customerName } = useContext(MyContext)
    let navigate = useHistory();
    const [totalHours, setTotalHours] = useState([])
    //This will display the total of each shift being viewed.  What is being viewed can change
    //as the brush is used
    const [s1TotalBeingViewed, setS1TotalBeingViewed] = useState(0);
    const [s2TotalBeingViewed, setS2TotalBeingViewed] = useState(0);
    const [s3TotalBeingViewed, setS3TotalBeingViewed] = useState(0);
    const [grandTotalBeingViewed, setGrandTotalBeingViewed] = useState(0);
    const [sumThreeByDate, setSumThreeByDate] = useState(null)
    const [shasThreeShifts, setHasThreeShifts] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showAllData, setShowAllData] = useState(false);
    const [showStacked, setShowStacked] = useState(true);
    const [cutOffDate, setCutOffDate] = useState(() => {
        const today = addMonths(new Date(), -15); //-15 so we can do quarter comparison from prev. year
        var year = today.getFullYear();
        year -= 2000; //get 2digit year
        //year -= 1; //to get 1 year ago
        year *= 100; //shift it over to the left 2 places
        var month = today.getMonth() + 1;//.getMonth() + 1;//january =0
        var acutOffDate = year + month;
        //console.log("year: " + year + " month: " + month + " cutOffDate: " + acutOffDate);
        return acutOffDate;
    });
    const { showHideBar, handleLegendClick } = useGraphLegend(); //this contains the bars that are hidden


    const sixMonthLookBack = (monthLookBack) => {
        const today = addMonths(new Date(), -1 * monthLookBack);
        var year = today.getFullYear();
        year -= 2000; //get 2digit year
        //year -= 1; //to get 1 year ago
        year *= 100; //shift it over to the left 2 places
        var month = today.getMonth() + 1;//.getMonth() + 1;//january =0
        var acutOffDate = year + month;
        //console.log("year: " + year + " month: " + month + " cutOffDate: " + acutOffDate);
        return acutOffDate;
    };

    useEffect(() => {
        if (totalHours?.length > 0) {
            handleBrushOnChange({ startIndex: 0, endIndex: totalHours.length - 1 });
        }
    }, [totalHours]);

    useEffect(() => {
        setAnalyticsDailyDataLong(null);
        setAnalyticsDailyDataShort(null);
    }, [selectedMachines]);

    useEffect(() => {

        if (uptimeData) {
            setHasThreeShifts(uptimeData[0]["s3hours"] != null);
            // callWorker();
            console.log("UptimeAnalyticsDaily analyticsDailyData: " + analyticsDailyDataShort?.length + " showAllData: " + showAllData);
            if ((!showAllData && !analyticsDailyDataShort) || (showAllData && !analyticsDailyDataLong)) 
            {
                setIsLoading(true);
                var totals = getDailyData();
                if (showAllData) {
                    setAnalyticsDailyDataLong(totals);
                }
                else {
                    setAnalyticsDailyDataShort(totals);
                }
                setTotalHours(totals);
                setIsLoading(false);
                //console.log("called getDailyData()");
            }
            else {
                setIsLoading(false);
                if (showAllData) {
                    setTotalHours(analyticsDailyDataLong);
                }
                else {
                    setTotalHours(analyticsDailyDataShort);
                }
            }


        }

    }, [uptimeData, cutOffDate, analyticsDailyDataShort, analyticsDailyDataLong]);

    const callWorker = async () => {
        console.log("removeFunction called0");

        const instance = new MyClass();
        const ret = instance.logSomething();
        console.log("logger", ret);
        console.log("removeFunction called3");
    };
    function callback(value) {
        console.log("obj.counter: " + value);
    }

    const getDailyData = () => {
        // console.log("done 1");

        //console.log("UptimeAnalyticsTotal useEffect");
        //This will create 3 collections.  1 for each shift
        //and sum the daily uptime across machines.
        const sumOneDate = [];
        const sumTwoDate = [];
        const sumThreeDate = [];
        sumShiftByDate(sumOneDate, "s1hours");
        sumShiftByDate(sumTwoDate, "s2hours");
        //var hasThreeShifts = false;
        if (uptimeData[0]["s3hours"] != null) {
            // hasThreeShifts = true;
            sumShiftByDate(sumThreeDate, "s3hours");
            setSumThreeByDate(sumThreeDate);
        }
        // console.log("done sumOneDate.length: " + sumOneDate.length);
        //setSumOneByDate(sumOneDate);
        // setSumTwoByDate(sumTwoDate);
        const totalHrs = [];
        for (var i = 0; i < sumOneDate.length; i++) {
            var total = 0;
            if (shasThreeShifts) {
                total = sumOneDate[i].sum + sumTwoDate[i].sum + sumThreeDate[i].sum;
            }
            else {
                total = sumOneDate[i].sum + sumTwoDate[i].sum;
            }
            //round to 0 decimal places
            total = Math.round(total);
            if (uptimeData[0]["s3hours"] != null) {
                totalHrs.push({ date: sumOneDate[i].date, sum: total, shift1: sumOneDate[i].sum, shift2: sumTwoDate[i].sum, shift3: sumThreeDate[i].sum });
            }
            else {
                totalHrs.push({ date: sumOneDate[i].date, sum: total, shift1: sumOneDate[i].sum, shift2: sumTwoDate[i].sum });
            }
            //  console.log("total hours for date: " + sumOneDate[i].date + " " + total);
        }

        // console.log("done");
        return totalHrs;

    };

    const sumShiftByDate = (sumDate, shiftProp) => {
        //get distinct dates
        //console.log("start sumShiftByDate 0 ");
        const uniqueDates = [];
        for (let value of uptimeData) {
            //we only go back 1 year so only take yearMonth > cutOffDate
            //break this appart from "10/01/20" "month/year"
            var parts = value.date.split('/');
            var monthYear = parts[2] + parts[0];
            var imonthYear = parseInt(monthYear);
            if (imonthYear >= cutOffDate) {

                if (uniqueDates.indexOf(value.date) === -1) {
                    uniqueDates.push(value.date);
                }
            }
        }
        //console.log("num uniqueDates: " + uniqueDates.length);
        const sumReducer = (accumulator, currentValue) => accumulator + currentValue;
        //console.log("done w/ sumShiftByDate 1 ");
        var selectCount = selectedMachines.length;
        uniqueDates.forEach((aDate) => {
            let filterByDate = uptimeData.filter(a => {
                //Needs to take into account the machine selection.
                //The issue w/ this is machines that we have monitored in the past but no longer do
                //will not be included in these totals...ugh
                //This turned out to be very costly
                var count = 0;
                while (count < selectCount) {
                    if (selectedMachines[count] === a.machinename) {
                        // if (selectedMachines.indexOf(a.machinename) !== -1) {
                        return (a.date === aDate)
                    }
                    count++;
                    //  }
                }
                return false;
            });

            let hours = filterByDate.map(a => a[shiftProp]);
            let sum = 0;
            if (hours.length > 0) {
                sum = hours.reduce(sumReducer);
            }
            //round to 0 decimal places
            sum = Math.round(sum * 10) / 10;
            //console.log("sum hours for date: " + aDate + " " + sum + " hours.length: " + hours.length);
            sumDate.push({ date: aDate, sum: sum });

        });
       // console.log("done w/ sumShiftByDate 2 ");
    };


    const handleBrushOnChange = (index) => {
        //console.log("Brush onChange index: " + index.startIndex + " end index " + index.endIndex);
        var viewedHours = totalHours.slice(index.startIndex, index.endIndex + 1);
        var s1Total = viewedHours.reduce((a, b) => +a + +b.shift1, 0);
        var s2Total = viewedHours.reduce((a, b) => +a + +b.shift2, 0);
        var s3Total = viewedHours.reduce((a, b) => +a + +b.shift3, 0);
        //console.log("s1Total: " + s1Total + " s2Total: " + s2Total + "s3Total: " + s3Total);
        var s1 = Math.round(s1Total);
        var s2 = Math.round(s2Total);
        var s3 = shasThreeShifts ? Math.round(s3Total) : 0;
        var grandTotal = s1 + s2 + s3;
        setS1TotalBeingViewed(s1);
        setS2TotalBeingViewed(s2);
        setS3TotalBeingViewed(s3);
        setGrandTotalBeingViewed(grandTotal);
        //totalHours.shift2
        //totalHours.shift3
    }
    const handleChange = () => () => {

        console.log("handleChange showAllData: " + showAllData);
        if (!showAllData) {
            //reset the 
            setCutOffDate(sixMonthLookBack(48));
        }
        else {
            setCutOffDate(sixMonthLookBack(6));
        }
        //set all selected to the opposite of the current value
        setShowAllData(!showAllData);
    }

    const handleBarClick = (val, shiftName) => {
        console.log("handleBarClicks val: " + val.date + " shiftName: " + shiftName);
        setArchiveDate({ date: parse(val.date, "M/d/yy", new Date()), shiftName: shiftName });
        navigate.push('/start/' + customerName);
    }




    return (
        <div >
            <div style={{ display: 'flex' }}>
                <div style={{ marginTop: '2px' }}>
                    <h4>Daily Totals: {s1TotalBeingViewed}(Shift1) + {s2TotalBeingViewed}(Shift2) {shasThreeShifts ? " + " + s3TotalBeingViewed + "(Shift3)" : ""} = {grandTotalBeingViewed}</h4>
                </div>
                <div style={{ position: 'absolute', right: '0px' }}>
                    Stacked Bar<Checkbox style={{ width: 30 }} checked={showStacked} onChange={() => { setShowStacked(!showStacked) }} />
                    <div style={{ marginTop: '0px', marginLeft: '-32px' }}>
                        Show All History
                        <Checkbox
                            edge="end"
                            style={{ width: 30, height: 10 }}
                            checked={showAllData}
                            onChange={handleChange()}
                            tabIndex={-1}
                            disableRipple

                        />
                    </div>
                </div>

            </div>
            {isLoading ? <div><Loader /></div> :
                <div>
                    <ResponsiveContainer width={"100%"} height={400} >
                        <BarChart
                            data={totalHours}
                            syncId="anyId"
                            margin={{
                                top: 10, right: 30, left: 0, bottom: 0,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend onClick={handleLegendClick} />
                            <Bar type="monotone" dataKey="shift1" name="Shift 1" hide={showHideBar.indexOf('shift1') !== -1} stroke="#8884d8" fill="#8884d8" stackId={showStacked ? "stack" : "stack3"} onClick={(event) => { handleBarClick(event, 'shift1') }} barSize={showStacked ? 5 : 150} />
                            <Bar type="monotone" dataKey="shift2" name="Shift 2" hide={showHideBar.indexOf('shift2') !== -1} stroke="#82ca9d" fill="#82ca9d" stackId={showStacked ? "stack" : "stack2"} onClick={(event) => { handleBarClick(event, 'shift2') }} barSize={showStacked ? 5 : 150} />
                            {shasThreeShifts ?
                                <Bar type="monotone" dataKey="shift3" name="Shift 3" hide={showHideBar.indexOf('shift3') !== -1} stroke="#dc3912" fill="#dc3912" stackId={showStacked ? "stack" : "stack1"} onClick={(event) => { handleBarClick(event, 'shift3') }} barSize={showStacked ? 5 : 150} /> : <div></div>}
                            {/* <LabelList dataKey="sum" position="insideTop"/> */}

                            <Brush onChange={handleBrushOnChange} />
                        </BarChart>

                    </ResponsiveContainer>
                </div>}

        </div>
    );
};

export const obj = {
    counter: 0,
    inc() {
        console.log("worker inc called0");
        this.counter++;
    },
};


//Comlink.expose(()=>{return 4});
Comlink.expose(obj);
export default UptimeAnalyticsDaily;