import React, { useState, useEffect } from 'react';
// import { MyContext } from './MyContext';
import { useParams } from 'react-router-dom';
import ReactSpeedometer from "react-d3-speedometer";
//import Thermometer from 'react-ui-thermometer';
import UtilThermometer from './UtilThermometer';

const UtilizationGauge = ({ sitedata, selectedMachines }) => {
    // const { selectedMachines, setCustomerName } = useContext(MyContext);
    const [segmentStops, setSegmentStops] = useState([]);
    const [currUtil, setCurrUtil] = useState(0);
    
    
    useEffect(() => {
        
        if (sitedata) {
            console.log("UtilizationGauge useEffect sitedata");
            initUtilizationChart();
        }

    }, [sitedata, selectedMachines]);


    const initUtilizationChart = ()=> {

        //we cannot use this.sitedata.currentutilization and this.sitedata.averageutilization b/c it takes into consideration all the machines
        //this calculation below looks at only the machines selected.
        var avgUtilization = 0;
        var histAvgUtilization = 0;
        var avgCount = 0;
        var histAvgCount = 0;
        sitedata.machines.forEach(element => {
          if (selectedMachines.indexOf(element.machinename) !== -1) {
            if (element.averageuptimepercent > 0) {
              histAvgUtilization += element.averageuptimepercent;
              histAvgCount++;
              // console.log("MUTDashboardComponent.initUtilizationChart() avgUtilization: " + histAvgUtilization + " avgCount: " + histAvgCount);
            }
            if (element.currentuptimepercent > 5) {
              avgUtilization += element.currentuptimepercent;
              avgCount++;
              // console.log("MUTDashboardComponent.initUtilizationChart() avgUtilization: " + avgUtilization + " avgCount: " + avgCount);
            }
          }
        });
        if (avgUtilization > 0) {
          avgUtilization /= avgCount;
          avgUtilization = Math.trunc(avgUtilization);
        }
        if (histAvgUtilization > 0) {
          histAvgUtilization /= histAvgCount;
          histAvgUtilization = Math.trunc(histAvgUtilization);
        }
        console.log("UtilizationGauge.initUtilizationChart() avgUtilization: " + avgUtilization);
        console.log("UtilizationGauge.initUtilizationChart() histAvgUtilization: " + histAvgUtilization);
    
       
       
        setSegmentStops([0,histAvgUtilization - 10, histAvgUtilization + 5,100]);
        setCurrUtil(avgUtilization);
      }




    return (
        <div>
            <ReactSpeedometer 
            segments={3}
            minValue={0}
            maxValue={100}
            value={currUtil}
            forceRender={true}
            needleColor="blue"
            needleHeightRatio={0.75}
            currentValueText="Utilization ${value}%"
            segmentColors={['#EA4228', '#F5CD19', '#5BE12C']}
            customSegmentStops={segmentStops}
            />
            <UtilThermometer theme={'light'} value={currUtil} max={100} steps={50} size={'large'} height={180} />
            </div>
    );
}

export default UtilizationGauge;
