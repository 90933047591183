import { useState } from 'react';

const useGraphLegend = () => {
    const [showHideBar, setShowHideBar] = useState([]); //this contains the bars that are hidden

    const handleLegendClick = (val) => {
        console.log("handleLegendClick val: " + val.dataKey);
        var newShowHide = [];
        if (showHideBar.indexOf(val.dataKey) !== -1) {
            //if the item is  in our collection then it is currently hidden
            //and we need to remove it is shown
            newShowHide = showHideBar.filter(a => a != val.dataKey);
        }
        else {
            //the item is not in our list but we need to add it.
            newShowHide = [...showHideBar];
            newShowHide.push(val.dataKey);
        }
        setShowHideBar(newShowHide);

    }
    return {
        showHideBar,  handleLegendClick
    };
}

export default useGraphLegend;