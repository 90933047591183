//export const siteurl='http://sites.mutcloud.com/';
//export const awsgatewayurl='https://py1s8r7joc.execute-api.us-west-2.amazonaws.com/production/customers/';
//export const awscustomerdatagatewayurl='https://py1s8r7joc.execute-api.us-west-2.amazonaws.com/production/customerdata/';
//3/29/2019 we are fully in aws for or data.  We use the aws api gateway to access all the dashboard data,
//downtime reason codes, uptime reports, floorplan, display config, etc.  Most everything is in s3.  
//downtimereasoncodes and dashboard data is in dynamodb and accessed via lambda
export const awscustomerdatagatewayurl='https://py1s8r7joc.execute-api.us-west-2.amazonaws.com/production/';

//customers that have a 3rd shift vs the default 2 shift support
export const thirdShiftCustomers=["Awhamilton","Bowden","Divfit","Criterion", "Airway","Acme","Prmachine","Amgind","Lrwtool"];

//customers the provide a wire frame floor plan image.
export const floorplanImageCustomers =["Acme", "Nelson", "Awhamilton","Usord"];

//customers the support part counting capabilites we optional 
//display ui components
export const partCountCustomers=["Airway", "Divfit", "Cerco", "Amgind", "Acme", "Cesco", "Lehner"];