import React, { useState, useEffect, useContext } from 'react';
import { BarChart, Bar, ResponsiveContainer, LabelList, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { MyContext } from './MyContext';
import useUptimeData from './useUptimeData';

const UptimeAnalyticsTotal = () => {
    const { uptimeData, selectedMachines } = useContext(MyContext)
    const { sumOneByDate, sumTwoByDate, sumThreeByDate } = useUptimeData();
    const [totalHours, setTotal] = useState([])


    useEffect(() => {


        var hasThreeShifts = false;
        if (sumThreeByDate != null) {
            hasThreeShifts = true;
        }
        const totalHrs = [];
        for (var i = 0; i < sumOneByDate.length; i++) {
            var total = 0;
            if (hasThreeShifts) {
                total = sumOneByDate[i].sum + sumTwoByDate[i].sum + sumThreeByDate[i].sum;
            }
            else {
                total = sumOneByDate[i].sum + sumTwoByDate[i].sum;
            }
            //round to 0 decimal places
            total = Math.round(total);
            totalHrs.push({ date: sumOneByDate[i].date, sum: total });
            //  console.log("total hours for date: " + sumOneDate[i].date + " " + total);
            setTotal(totalHrs);
        }

    }, [sumOneByDate, sumTwoByDate, sumThreeByDate]);



    return (
        <div  >
            <h4>Monthly Total</h4>
            <ResponsiveContainer width={"100%"} height={200} >
                <BarChart
                    data={totalHours}
                    syncId="anyId"
                    margin={{
                        top: 10, right: 30, left: 0, bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Bar type="monotone" dataKey="sum" stroke="#8884d8" fill="#8884d8" >
                        <LabelList dataKey="sum" position="insideTop" />
                    </Bar>
                    {/* <Brush /> */}
                </BarChart>
            </ResponsiveContainer>

        </div>
    );
};
export default UptimeAnalyticsTotal;