import { useState, useContext } from 'react';
import { MyContext } from './MyContext';
import * as datafetcher from './ContextDataFetcher'
import * as downtimeUtils from './DowntimeUtils';
import * as moment from 'moment';

const useDowntimeDialog = () => {
  const { data, dialogReasonCodes,  persistedDowntimeReasonCodes, setPersistedDowntimeReasonCodes } = useContext(MyContext);
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [machineName, setMachineName] = useState(null);
  const [timeperiodstart, setTimeperiodstart] = useState(null);
  const [timeperiodend, setTimeperiodend] = useState(null);
  const [message, setMessage] = useState(null);
  const [customer, setCustomer]=useState(null);
  const [archiveDir, setArchiveDir] = useState(null);


  const handleClickOpen = (machineName, timeperiodstart, timeperiodend, title, message, customer) => {
    setMachineName(machineName);
    setTimeperiodstart(timeperiodstart);
    setTimeperiodend(timeperiodend);
    setMessage(message);
    setCustomer(customer);
    //setArchiveDir(archiveDir);
    setOpen(true);
    //persist the details of the down time period selected
  };

  const handleClose = (value) => {
    setOpen(false);
    
    //persist selected value
    if (value) {
      setSelectedValue(value);
      //calculate the duration
      var selectedValues = [];
      var minutes = timeperiodend - timeperiodstart; //millisecond
      minutes = minutes / 1000.0; //convert to seconds
      minutes = minutes / 60.0; //convert to minutes
      var reasonSelected = null;
      console.log("useDowntimeDialog.handleClose() value: " + value.reasoncode + " id: " + value.reasonid);
      if (value.reasonid)
      {
        selectedValues.push(value.reasonid); 
      }
      var reasontext=value.reasontext;//TODO capture down time optional text
      var machineDownTimes = downtimeUtils.initDownTimeReaons(persistedDowntimeReasonCodes);
      //we persist values even if nothing was selected.
      //Why?  Because the user could be unselecting (i.e. deleting/removing) a 
      //reason code that was previously selected.
      downtimeUtils.setReason(machineName, timeperiodstart, selectedValues, 0, reasontext, machineDownTimes);
      console.log("downtime dialog saving reasontext: " + reasontext);
      var duration = timeperiodend - timeperiodstart;
      //create a readable string that is persisted w/ the .json for debug reasons when we try to view the
      //.json data directly
      var readDebugString = moment(timeperiodstart)
      //since all time is in customer time zone we force it as UTC to remove the offset component.
      var utcTime = moment.utc(readDebugString).format("MM-DD-YYYYTHH:mm:ss")
      if (!reasontext) {
        reasontext = null;
      }
      var currArchive= datafetcher.getCurrentDateShiftString(data);
      console.log("useDowntimeDialog.handleClose()  currArchive: " + currArchive);
      
      //write to dynamoDb the new value
      downtimeUtils.persistDownTimeReasonCodes({ machinename: machineName, timeperiodstart: timeperiodstart, timeperiodend: timeperiodend, reasoncodes: selectedValues, duration: duration, text: reasontext, debug: utcTime }, customer, currArchive, setPersistedDowntimeReasonCodes);
      

    }
  };

  return {
    open,
    handleClickOpen,
    handleClose,
    selectedValue,
    setSelectedValue,
    setOpen
  }
};

export default useDowntimeDialog;