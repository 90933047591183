import React, { useContext, useEffect, useState } from 'react';
import { BarChart, Bar, ResponsiveContainer, Legend, LabelList, Brush, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { MyContext } from './MyContext';
import * as datafetcher from './ContextDataFetcher'
import { colors } from './DownTimeTotals';
import parse from 'date-fns/parse'
import useGraphLegend from './useGraphLegend';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import { useHistory } from 'react-router-dom'; //when updating to v6 then useNavigate will be used
import Typography from '@material-ui/core/Typography';

const AnalyticsDowntime = () => {
    const { customerName, downtimeReport, setDowntimeReport, dialogReasonCodes, selectedMachines, setArchiveDate } = useContext(MyContext);
    const [txDowntime, setTxDowntime] = useState([]);
    const [shiftOneDowntime, setShiftOneDowntime] = useState([]);
    const [shiftTwoDowntime, setShiftTwoDowntime] = useState([]);
    const [shiftThreeDowntime, setShiftThreeDowntime] = useState([]);
    const { showHideBar, handleLegendClick } = useGraphLegend(); //this contains the bars that are hidden
    const [showStacked, setShowStacked] = useState(false);
    const [shiftName, setShiftName] = useState("shift1");
    let navigate = useHistory();

    useEffect(() => {
        if (customerName && !downtimeReport) {
            datafetcher.getDowntimeReport(customerName, setDowntimeReport);
        }
    }, [customerName]);
    useEffect(() => {
        if (downtimeReport && dialogReasonCodes) {
            //transform this data
            var newtxdowntime = [];
            var shiftonedowntime = [];
            var shifttwodowntime = [];
            var shiftthreedowntime = [];
            (downtimeReport).forEach(element => {
                var downTimeTotals = getDowntimesByReasoncode(element);
                // var dateshift = element.date + '_' + element.shift;
                var aDate = parse(element.date, "M-d-yyyy", new Date())
                // var lastIndex = element.date.lastIndexOf("-");
                //var strDate = element.date.slice(0,lastIndex);
                var strDate = element.date.replaceAll("-", "/");
                //newtxdowntime.push({ date: element.date, totals: downTimeTotals, datetimeseconds: element.datetimeseconds });
                if ("shift1" == element.shift) {
                    shiftonedowntime.push({ date: strDate, totals: downTimeTotals, datetimeseconds: element.datetimeseconds });
                }
                if ("shift2" == element.shift) {
                    shifttwodowntime.push({ date: strDate, totals: downTimeTotals, datetimeseconds: element.datetimeseconds });
                }
                if ("shift3" == element.shift) {
                    shiftthreedowntime.push({ date: strDate, totals: downTimeTotals, datetimeseconds: element.datetimeseconds });
                }

            });
            shiftonedowntime.sort((a, b) => (a.datetimeseconds > b.datetimeseconds) ? 1 : -1);
            shifttwodowntime.sort((a, b) => (a.datetimeseconds > b.datetimeseconds) ? 1 : -1);
            shiftthreedowntime.sort((a, b) => (a.datetimeseconds > b.datetimeseconds) ? 1 : -1);
            setShiftOneDowntime(shiftonedowntime);
            setShiftTwoDowntime(shifttwodowntime);
            setShiftThreeDowntime(shiftthreedowntime);
            //init to shiftone report
            setTxDowntime(shiftonedowntime);
        }
    }, [downtimeReport, dialogReasonCodes, selectedMachines]);

    //this takes a DowntimeData object and returns an ordered list of downtime values in
    //order of the columnListHeadings.
    const getDowntimesByReasoncode = (dateDowntime) => {

        //for each machine we'll look at their downtimes and how much time associated w/ each.
        //they get totaled up based on reasoncode
        let downtimeTotals = new Array(dialogReasonCodes.length);
        //init the unlabeled category.  unlabeled is at the 0 index and average is the last index
        downtimeTotals[0] = 0;
        downtimeTotals[dialogReasonCodes.length - 1] = 0;
        if (dateDowntime.totaldowntimemins) {
            //since we have a total for this date/shift we'll add the value
            // console.log("downtime totaldowntimemins: " + downtime.totaldowntimemins);
            //add the unlabeled to the first category

            //we will store this in minutes so we can subtract from it more 
            //accurately and convert it to hours at the end of processing
            //each labeled reason code
            downtimeTotals[0] = parseInt(dateDowntime.totaldowntimemins);
        }

        for (let machineName in dateDowntime.machinelist) {
            var includedMachineDowntime = true;
            //09012019 if the machine is not selected from the machine selector then we don't
            //want to include it's down time in each of the individual buckets of 
            //labeled down time.  But since the totaldowntimemins does include
            //the unselected machines contribution then we still want to subtract
            //it's portion from the total.
            if (selectedMachines.indexOf(machineName) == -1) {
                includedMachineDowntime = false;
            }
            for (let reasonCodeNum in dateDowntime.machinelist[machineName]) {

                var total = Number(dateDowntime.machinelist[machineName][reasonCodeNum]);
                //3/12/2019 since we moved to AWS our python numbers have been 
                //converted to decimals instead of integers.  So we need to 
                //convert them back here so we don't have dict keys of 4.0 when
                //we want 4.  But it's important we do this conversion after
                //getting the total.
                // dtKey=parseInt(dtKey).toString();
                if (!downtimeTotals[reasonCodeNum]) {
                    //need to init this array position to zero otherwise it's NAN and you can't += it
                    downtimeTotals[reasonCodeNum] = 0;
                }
                if (includedMachineDowntime) {
                    var downTimeInHrs = (total / 60.0);
                    downtimeTotals[reasonCodeNum] += downTimeInHrs;
                }
                //keep track of the average by adding this category of downtime
                //dailyDownTimeTotal += total;
                //subract from the unlabeled category
                if (downtimeTotals[0] > 0) {
                    //console.log("downtimeTotals[unlabeledIndex] subtracting: " + total + " for key: " + this.columnHeadings[dtKey] + " from: " + downtimeTotals[unlabeledIndex] );
                    downtimeTotals[0] -= total;
                    //console.log("downtimeTotals[unlabeledIndex] new value: " + downtimeTotals[unlabeledIndex] );
                }
                console.log("DowntimereportingComponent.addDateDownTime dtKey: " + reasonCodeNum + " total: " + total);
            }
        }

        //round each value for presentation
        (downtimeTotals).map((element, index) => {
            downtimeTotals[index] = Math.round(element * 10) / 10;
        })
        //convert our unlabeled category to hrse
        var totalInHrs = downtimeTotals[0] / 60.0;
        //b/c of when down time gets calculated compared to the final report
        //we might be off in total hours of data captured which has let to some negative
        //number for unlabled.  So let's not display negative
        totalInHrs = Math.max(0, totalInHrs);
        totalInHrs = Math.round(totalInHrs * 10) / 10;
        downtimeTotals[0] = totalInHrs;
        return downtimeTotals;

    }

    const handleShiftChange = (event) => {

        console.log(" AnalyticsDowntime handleShiftChange event: " + event.target.value);
        var selectedShift = event.target.value;
        if ("shift1" == selectedShift) {
            setTxDowntime(shiftOneDowntime);
        }
        else if ("shift2" == selectedShift) {
            setTxDowntime(shiftTwoDowntime);
        }
        else if ("shift3" == selectedShift) {
            setTxDowntime(shiftThreeDowntime);
        }
        setShiftName(selectedShift);
    };

    const handleBarClick = (val, shiftName) => {
        console.log("handleBarClick val: " + val.date + " shiftName: " + shiftName);
        setArchiveDate({ date: parse(val.date, "M/d/yyyy", new Date()), shiftName: shiftName });
        navigate.push('/start/' + customerName + '/downtimetotals');
    }

    const renderBars = () => {
        if (downtimeReport) {
            const bars = downtimeReport.map((amachine) => (
                <Bar type="monotone" key={amachine.totaldowntimemins} stroke="#8884d8" fill="#8884d8" >

                </Bar>
            ));
        }
    }

    const CustomBar = (props) => {
        const { stroke, fill, x, y, width, height } = props;

        {

            return <rect
                x={x}
                y={y}
                width={width}
                height={height}
                stroke={stroke}
                fill={fill}
            />
        }
    };
    const MyCustomBar = (props) => {
        const { stroke, fill, x, y, width, height, machinelist } = props;

        {

            return <rect
                x={x}
                y={y}
                width={width}
                height={height}
                stroke={stroke}
                fill={fill}
            />

        }
    };

    const calcValue = (props) => {
        const { value } = props;
        return value / 60;
    }

    return (

        <div>
            <div style={{ marginTop: '2px', marginLeft: '1px', display:'flex' }}>
            <Typography variant="subtitle2" align="center" >
                <Radio
                    value="shift1"
                    checked={shiftName == 'shift1'}
                    name="shiftRadio"
                    onChange={handleShiftChange} />
                    Shift 1
                    <Radio
                    value="shift2"
                    checked={shiftName == 'shift2'}
                    name="shiftRadio"
                    onChange={handleShiftChange} />
                        Shift 2
                    <Radio
                    value="shift3"
                    checked={shiftName == 'shift3'}
                    name="shiftRadio"
                    onChange={handleShiftChange} />
                        Shift 3
                        </Typography>
                <div style={{position:'absolute', right:'0px'}}>
                    Stacked Bar<Checkbox style={{ width: 30}} checked={showStacked} onChange={() => { setShowStacked(!showStacked) }} />
                </div>
            </div>
            <ResponsiveContainer width={"100%"} height={500} >
                <BarChart
                    data={txDowntime}

                    margin={{
                        top: 10, right: 30, left: 0, bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip offset={30} />
                    <Legend onClick={handleLegendClick} />
                    {dialogReasonCodes?.map((item, i) => {
                        return (<Bar stackId="a" shape={<MyCustomBar />} barSize={showStacked ? 5 : 150} name={item.name} hide={showHideBar.indexOf(`totals[${item.id}]`) !== -1} stackId={showStacked ? "stack" : { i }} dataKey={`totals[${item.id}]`} stroke={colors[item.id]} fill={colors[item.id]} onClick={(event) => { handleBarClick(event, shiftName) }} />)
                    })}
                    {/* <Bar type="monotone" dataKey="totaldowntimemins"  value={()=>{calcValue()}} shape={<MyCustomBar/>}  stroke="#8884d8" fill="#8884d8" ></Bar> */}



                    <Brush />
                </BarChart>
            </ResponsiveContainer>

        </div>

    );
}
export default AnalyticsDowntime;