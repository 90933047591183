import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from './MyContext';
import * as moment from 'moment';
import { Chart } from "react-google-charts";
import { colorBlue, colorGreen, colorYellow, colorRed } from './UtilizationGraph';
import * as myGlobals from './globals';
import * as downtimeUtils from './DowntimeUtils';
import useDowntimeDialog from './useDowntimeDialog';
import DowntimeDialog from './DowntimeDialog';
import * as datafetcher from './ContextDataFetcher'

const MachineTimeline = ({ machineNames, displayRowLabels = false, showSensorView=true }) => {
    const { data, dialogReasonCodes, persistedDowntimeReasonCodes, sArchiveDate } = useContext(MyContext);
    const [sTimelineOptions, setTimelineOptions] = useState([]);
    const [sTimelineData, setTimelineData] = useState([]);
    const [sShowDialog, setShowDialog] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [timelineHeight, setTimelineHeight] = useState(100);
    const dateFormatString = 'YYYY-MM-DDTHH:mm:ss';
    const { open, handleClickOpen, handleClose, selectedValue, setSelectedValue, setOpen } = useDowntimeDialog();


    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return function cleanupListener() {
            window.removeEventListener('resize', handleResize)
        }
    }, []);
    const handleResize = (e) => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        //we dynamically set the height of the div below to fit all the timelines if there is more than 1
        if (machineNames.length > 1) {
            var timelineDivHeight = machineNames.length * 50;
            console.log("MachineTimeline useEffect data timelineDivHeight: " + timelineDivHeight);
            setTimelineHeight(timelineDivHeight);
        }
    }, [machineNames]);


    useEffect(() => {
        if (data && machineNames && dialogReasonCodes) {


            displayMachineTimelines(machineNames);
        }

    }, [data, machineNames, dialogReasonCodes, persistedDowntimeReasonCodes]);


    const initTimelineDataTable = () => {
        var timelineoptions = {
            legend: 'none',
            title: 'click on a timeline to view the sensor data',

            //used the downtime reason code dialog and clicked Ok
            tooltip: {
                // isHtml:true,
                trigger: 'focus',
            },
            //colors: ['#dc3912', '#109618'],
            timeline: {
                showRowLabels: displayRowLabels,
                showBarLabels: true
                //this will make the timeline bar thinner
                //rowLabelStyle: { fontSize: 11 }, barLabelStyle: { fontSize: 6 } 
                //rowLabelStyle will format all row labels
                //rowLabelStyle: {fontName: 'Helvetica', fontSize: 24, color: '#ff0000',allowHtml: true },
                //            barLabelStyle: { fontName: 'Garamond', fontSize: 14,allowHtml: true }
            },
            hAxis: {
                //hour12:true,
                format: 'h:mma',
                fontSize: 5,
            },
            allowHtml: true
            //  avoidOverlappingGridLines: false
        };



        // //clear the collection
        // var timelinedata = [];
        // timelinedata.length = 0;
        // timelinedata[0] =
        //   [
        //     { type: 'string', id: 'timeline' },
        //     { type: 'string', id: 'onoff' },
        //     //{ type: 'string', role: 'tooltip'},//the tooltip HAS to be in the 3rd column otherwise it'll never show up as an html provided tool tip.
        //     { type: 'string', role: 'style' },
        //     { type: 'date', id: 'End', },
        //     { type: 'date', id: 'Start' },
        //     // { type: 'string', role: 'tooltip'},//the tooltip HAS to be in the 3rd column otherwise it'll never show up as an html provided tool tip.

        //   ];
        //this.displayingTimeline = false;
        setTimelineOptions(timelineoptions);
        //setTimelineData(timelinedata);

    }
    const displayMachineTimelines = () => {
        //this.currentTimeline = category;
        //We call this init here and not in drawGraph() like the other init()'s b/c the dataTable needs 
        //to be cleared everytime this is called otherwise the timeline grows w/ redundency
        initTimelineDataTable();

        //clear the collection
        var timelinedata = [];
        timelinedata.length = 0;
        timelinedata[0] =
            [
                { type: 'string', id: 'timeline' },
                { type: 'string', id: 'onoff' },
                //{ type: 'string', role: 'tooltip'},//the tooltip HAS to be in the 3rd column otherwise it'll never show up as an html provided tool tip.
                { type: 'string', role: 'style' },
                { type: 'date', id: 'End', },
                { type: 'date', id: 'Start' },
                // { type: 'string', role: 'tooltip'},//the tooltip HAS to be in the 3rd column otherwise it'll never show up as an html provided tool tip.

            ];
        for (var i = 0; i < data.machines.length; i++) {
            var machineName = data.machines[i].machinename
            if (machineNames.indexOf(machineName) === -1) {
                //this machine is not part of the selected machines list
                continue;
            }
            //console.log("machine.machinename " + this.sitedata.machines[i].machinename + " " + this.sitedata.machines[i].currentuptimepercent + "/" + this.sitedata.machines[i].averageuptimepercent);
            //console.log("machine.currentuptimepercent " + this.sitedata.machines[i].currentuptimepercent);
            //console.log("machine.averageuptimepercent " + this.sitedata.machines[i].averageuptimepercent);

            // console.log("machine.offon " + this.sitedata.machines[i].offon);
            var colorObj = { value: colorGreen };


            for (var j = 0; j < data.machines[i].offon.split(",").length; j++) {
                var color = 'color: #dc3912; opacity: 0.9'; //red
                var serverFillDownTimelineColor = 'color: #ffa500; opacity: 0.8';
                var downTimeExceededConfigMinuteslineColor = 'color: #aaaaaa';
                var downtimeWithText = 'color: #8A0829; opacity: 0.9';

                //expected format is onoff|startyyyyMMddHHmm|endyyyyMMddHHmm
                var onoffEvent = data.machines[i].offon.split(",")[j];
                var onoff = onoffEvent.split("|")[0];
                //update the color
                //if ('on' == onoff) {
                //7/10/2019 we can now include part count w/ the on segments
                //so we just check that the string starts w/ on  
                //7/13 probably just need starts with
                if (onoff.startsWith('on')) {
                    color = 'color: #109618; opacity: 0.8'; //green
                }
                //test if this is a server provided off period for 
                //a machine powered MUT
                if ('offServerProvided' == onoff) {
                    //this is a server provided event
                    //b/c this is a machine powered MUT
                    color = serverFillDownTimelineColor;
                    //we need to rename this text otherwise a
                    //new timeline category will be created.
                    onoff = 'off';
                }
                if ('offExceededConfigDowntime' == onoff) {
                    //this is a downtime that was labeled and exceeded
                    //the configured time for it.
                    color = downTimeExceededConfigMinuteslineColor;
                    //we need to rename this text otherwise a
                    //new timeline category will be created.
                    onoff = 'off';
                }


                var startTime = onoffEvent.split("|")[1];
                var startTimeYear = startTime.substr(0, 4);
                var startTimeMonth = startTime.substr(4, 2);
                var startTimeDay = startTime.substr(6, 2);
                var startTimeHour = startTime.substr(8, 2);
                var startTimeMin = startTime.substr(10, 2);
                //capture the endtime componenents
                var endTime = onoffEvent.split("|")[2];
                //calculate the duration
                //didn't work out too well b/c it messed up the red/green areas

                var a = moment.utc(startTime, 'YYYYMMDDHHmm');
                var b = moment.utc(endTime, 'YYYYMMDDHHmm');
                var durationTime = moment.duration(b.diff(a));
                var hours = durationTime.hours();
                durationTime.subtract(moment.duration(hours, 'hours'));
                var minutes = durationTime.minutes();
                //var minute = 
                var duration = hours.toString() + ':' + minutes;

                var endTimeYear = endTime.substr(0, 4);
                var endTimeMonth = endTime.substr(4, 2);
                var endTimeDay = endTime.substr(6, 2);
                var endTimeHour = endTime.substr(8, 2);
                var endTimeMin = endTime.substr(10, 2);
                //we treat these time values at UTC but then strip off the timezone info so it doesn't get converted to
                //to local time.  we leave the time in the client's local time.
                var machineName = data.machines[i].machinename;
                if (data.machines[i].machinenamealias) {
                    machineName += ' ' + data.machines[i].machinenamealias;
                }

                if (j == 0) {
                    // console.log("here");
                    //to keep the colors lined up w/ red for off and green for on
                    //we need the following fake entries....sucks
                    timelinedata.push(
                        [machineName + ' ' + data.machines[i].currentuptimepercent + '/' + data.machines[i].averageuptimepercent,
                            //  'link',
                            'off',//'tooltip',
                            'color: #dc3912',
                        //the month is zero based:  month The month as an number between 0 and 11 (January to December).
                        //the next 2 lines did not worked and was being displayed 4hrs off at Criterion i.e. EST and so the original 2 lines were reverted to
                        // new Date (a.format(this.dateFormatString)),
                        //  new Date (a.format(this.dateFormatString))]
                        new Date(parseInt(startTimeYear), parseInt(startTimeMonth) - 1, parseInt(startTimeDay), parseInt(startTimeHour), parseInt(startTimeMin), 0),
                        new Date(parseInt(startTimeYear), parseInt(startTimeMonth) - 1, parseInt(startTimeDay), parseInt(startTimeHour), parseInt(startTimeMin), 0)

                        ]
                    );
                    timelinedata.push(
                        [machineName + ' ' + data.machines[i].currentuptimepercent + '/' +
                            data.machines[i].averageuptimepercent,
                            //  'link',
                            'on', //'tooltip',
                            'color: #109618',
                        //the next 2 lines did not worked and was being displayed 4hrs off at Criterion i.e. EST and so the original 2 lines were reverted to
                        //new Date (a.format(this.dateFormatString)),
                        //new Date (a.format(this.dateFormatString))]
                        new Date(parseInt(startTimeYear), parseInt(startTimeMonth) - 1, parseInt(startTimeDay), parseInt(startTimeHour), parseInt(startTimeMin), 0),
                        new Date(parseInt(startTimeYear), parseInt(startTimeMonth) - 1, parseInt(startTimeDay), parseInt(startTimeHour), parseInt(startTimeMin), 0)

                        ]
                    );
                }


                //console.log("onoff: " + onoff);
                // console.log('startTime: ' + startTime + " startTimeHour: " + startTimeHour + " startTimeMin: " + startTimeMin);
                //console.log("endTime: " + endTime + " endTimeHour: " + endTimeHour + " endTimeMin: " + endTimeMin);

                var tooltip = '<div class="tooltip">' + data.machines[i].machinename + " " + duration + '</div>';
                //Using  new Date ( a.format(this.dateFormatString) ) does not work for all browsers 
                var dtStartTime = new Date(parseInt(endTimeYear), parseInt(startTimeMonth) - 1, parseInt(startTimeDay), parseInt(startTimeHour), parseInt(startTimeMin), 0);
                //  console.log("dtStartTime " + dtStartTime + " startTimeMonth " + startTimeMonth + " moment " + a.format('YYYY-MM-DDTHH:mm:ss') + " moment milliseconds: " + a);//2017-05-25T11:50:00
                var dtEndTime = new Date(parseInt(endTimeYear), parseInt(endTimeMonth) - 1, parseInt(endTimeDay), parseInt(endTimeHour), parseInt(endTimeMin), 0);

                //check if we have a downtime reason code for this segment
                var downtimeReasonCode = downtimeUtils.getReasonText(data.machines[i].machinename, a.valueOf(), persistedDowntimeReasonCodes, dialogReasonCodes);
                if (downtimeReasonCode.reasoncode) {

                    onoff += "-" + downtimeReasonCode.reasoncode;
                    //console.log("MUTDashboardComponent.displayMachineTimeline(): downtimeReasonCode :" + downtimeReasonCode.reasoncode + " onoff: " + onoff );//+ " this.timelinedata.length: " + this.timelinedata.length);
                    if (downtimeReasonCode.reasontext) {
                        //since we have text assoc. w/ this downtime we want a visually
                        color = downtimeWithText;
                    }
                }
                // console.log("MUTDashboardComponent.displayMachineTimeline() this.timelinedata.length: " + this.timelinedata.length);
                // console.log("onoff, color: " + onoff + ":" + color);
                timelinedata.push(
                    [machineName + ' ' + data.machines[i].currentuptimepercent + '/' + data.machines[i].averageuptimepercent,
                        //{v:this.sitedata.machines[i].machinename + ' ' + this.sitedata.machines[i].currentuptimepercent + '/' + this.sitedata.machines[i].averageuptimepercent, f:'color: #ff0000'},
                        //[{v:'Mike', f:'<a href=www.google.com>google</a>'},
                        // 'link',
                        onoff,// tooltip,
                        color,//'color: #FF8000',//tooltip,
                        dtStartTime,
                        dtEndTime]
                );

            }

        }
        setTimelineData(timelinedata);


    }


    //there is a crazy deffect in the timeline chart that keeps the tooltip floating
    //when the downtime dialog is displayed.  Then the user clicks ok on the dialog 
    //and the chart updates, the tooltip gets orphaned and stays painted on the screen 
    //permanatly.  You have to hit f5 to get it to go away.  This code is called
    //when the chart is clicked, finds the tooltip document element(s) and hides it/them.
    //it seems everytime the timeline gets refreshed an additional tooltip element gets
    //created and that is why we need to do a querySelectorAll and hide all of them.
    const findcharttooltip = () => {
        //the built-in tooltip
        var timelinetooltip = document.querySelectorAll('.google-visualization-tooltip:not([clone])');
        //remove previous clone when there is any
        //console.log("mutdashboard.findcharttootip() this.globaltootipref set for first time: " + timelinetooltip);
        if (timelinetooltip) {
            hideElement(timelinetooltip);
        }
    }

    //brute force way of hidding an element, used for the google timeline chart
    //tooltip i
    const hideElement = (elements) => {
        elements = elements.length ? elements : [elements];
        for (var index = 0; index < elements.length; index++) {
            //defensive coding, it seems there might not be a style property always.
            if (elements[index].style) {
                //console.log("mutdashboard.hideElement() tooltip: " + elements[index].style.display);
                elements[index].style.display = 'none';
            }
        }
    }

    //this handler is used when a user clicks on the timeline and wishes to 
    //add or edit a time down reason.  We only show the dialog for periods
    //on the timeline that are red i.e. machine is down.
    const selectTimelineHandler = ({ chartWrapper }) => {
        //this.findcharttooltip(); //for the timeline chart and hide it.
        var showdialog = sShowDialog;
        const timelinechart = chartWrapper.getChart()
        var selectedItem = timelinechart.getSelection()[0];
        console.log("selectTimelinetHandler() column: " + selectedItem.row);
        setShowDialog(sShowDialog + 1);
        showdialog++;
        //console.log("setting this.showdialog begin: " + this.showdialog);
        if (selectedItem.row) {

            var topping = sTimelineData[selectedItem.row][0];
            var onoff = sTimelineData[selectedItem.row + 1][1]; //for whatever reason we need to add 1 to the row to get the correct onoff value
            var startTime = sTimelineData[selectedItem.row + 1][3];
            var momentStartTime = moment(startTime);
            var utcStartMoment = moment.utc(momentStartTime.format(dateFormatString));
            //console.log("MUTDashboardComponent.selectTimelinetHandler() momentStartTime: " + momentStartTime.format() + " utcMoment " + utcStartMoment.format());
            // console.log("MUTDashboardComponent.selectTimelinetHandler() momentStartTime: " + momentStartTime + " utcMoment " + utcStartMoment);
            // console.log("selectTimelinetHandler() startTime: " + startTime.getTime() + " utc millis: " + startTime.getUTCMilliseconds());

            var offTime = sTimelineData[selectedItem.row + 1][4];
            var momentEndTime = moment(offTime);
            var utcEndMoment = moment.utc(momentEndTime.format(dateFormatString));

            //console.log('The user selected ' + topping + ' onoff ' + onoff + ' startTime ' + startTime + ' offTime ' + offTime);
            //alert('The user selected ' + topping + ' onoff ' + onoff + ' startTime ' + startTime + ' offTime ' + offTime);
            var machineName = (topping + "").split(" ")[0]; //For the shopfloor display there is a prefix +/- so we need the second element
            //alert (machineName);
            var foundMachine = null;
            data.machines.forEach(machine => {
                if (machine.machinename == machineName) {
                    //found our machine instance
                    foundMachine = machine;
                    if (!foundMachine.reasoncodes) {
                        //init the reasoncodes property
                        foundMachine.reasoncodes = "";
                    }
                }
            });
            console.log("selected timeline: " + machineName);
            //if we are viewing archived data then disable 
            //downtime labeling for now
            if (onoff.startsWith("off")) {
                console.log("before toggle");
                var index = onoff.indexOf("-");
                if (index != -1) {
                    //then there is already a reason code set for this down time
                    //and we want to show that on the dialog
                    var currReasonCode = onoff.split('-')[1];
                    //console.log("MachindTimeline.selectTimelineHander() found existing downtime reasoncode: " + currReasonCode);
                    var downtimeReasonCode = downtimeUtils.getReasonText(machineName, utcStartMoment.valueOf(), persistedDowntimeReasonCodes, dialogReasonCodes);
                    if (downtimeReasonCode.reasoncode) {
                    }
                    setSelectedValue(downtimeReasonCode);

                }
                else if (selectedValue != '') {
                    //clear any previous
                    setSelectedValue('');
                }

                handleClickOpen(machineName, utcStartMoment.valueOf(), utcEndMoment.valueOf(), startTime.toLocaleString(), "some message", data.customer);

            }
            else if (showSensorView && showdialog == 1) {
                //   console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!selected timeline: " + machineName + " this.viewingArchiveData: " + this.viewingArchiveData + " this.archiveDir: " + this.archiveDir);
                //originally we would redirecto the raw sensor data page.
                //we might turn this back on when you click a green area but that would be confusing.
                var siteurl = myGlobals.awscustomerdatagatewayurl + 'image/';//'https://py1s8r7joc.execute-api.us-west-2.amazonaws.com/production/image/';//'http://mutcloud.hopto.org/';//myGlobals.siteurl;
                //03/29/2019 we not storing histutil in aws s3
                //var url2 = siteurl + this.sitedata.customer + '/histUtil/' + machineName + '.jpg';

                //9/15/2019 I'm not sure if there is a specific reason this.sitedata.customer is being used vs this.customer but
                //there's not a need to change it.  This is just raising the awareness so if a specific reason does surface we can
                //document it.

                //if we are viewing archived data then this.archiveDir will have a value otherwise it'll be empty string
                var url1 = siteurl + data.customer + '/' + machineName + '.jpg';

                if (sArchiveDate) {
                    console.log("MachineTimeline.selectTimelineHandler() we are viewing an archive: " + sArchiveDate?.date)
                    var currString = datafetcher.getCurrentDateShiftString(data);
                    console.log("MachineTimeline.selectTimelineHandler() currString: " + currString)
                    url1 = siteurl + data.customer + '/Archive/' + currString + '/' + machineName + '.jpg';
                }

                newPopup(machineName, url1, 800, 300);
                setShowDialog(0);//this.showdialog = 0;
            }

        }
        console.log("setting this.showdialog before check: " + showdialog);
        if (showdialog > 1) {
            setShowDialog(0);
            console.log("setting this.showdialog setting to 0: " + showdialog);
        }
        console.log("setting this.showdialog end: " + showdialog);
    }//end selectTimelinetHandler()

    //This popop displays multiple images in a window 
    //03/29/2019 we are no longer showing the .jpg version of the history since it's redudant
    //and we don't need to be taking up the space in aws s3
    //private newPopup(title, url1, url2, w, h) {
    const newPopup = (title, url1, w, h) => {
        var contents = '<title>' + title + '</title><img src="' + url1 + '" width="100%"></img>';// + '<img src="' + url2 + '" width="100%"></img>';
        var left = (windowWidth / 2) - (w / 2);
        var top = (windowHeight / 2) - (h / 2);
        //This solved a strange problem of this code being called from the selectTimelinetHandler in the else condition after the if condition was handled
        var previewWindow = window.open('', '', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
        if (previewWindow) {
            previewWindow.document.body.innerHTML = contents;
        }
    }

    const test =({e})=>{
        console.log("Hi e: " + e);
    }
    return (
        <div>
            <Chart
                chartType="Timeline"
                width={"100%"}
                height={timelineHeight}
                options={sTimelineOptions}
                data={sTimelineData}
                loader={<div>Loading Timeline</div>}
                chartEvents={[
                    // {eventName: 'ready', callback: test }, does not capture the label being clicked, bummer!
                    {eventName: 'select', callback: selectTimelineHandler }
                    
                ]}
            // selectEventHandler={selectTimelineHandler}

            />
            <DowntimeDialog selectedValue={selectedValue} open={open} onClose={handleClose} setOpen={setOpen} />
        </div>
    );
};
export default MachineTimeline;