import { useState, useContext, useEffect } from 'react';
import { MyContext } from './MyContext';



const useUptimeData = () => {
    const { uptimeData, selectedMachines } = useContext(MyContext)
    const [sumOneByDate, setSumOneByDate] = useState([])
    const [sumTwoByDate, setSumTwoByDate] = useState([])
    const [sumThreeByDate, setSumThreeByDate] = useState(null)
    const [avgDailyUptime, setAvgDailyUptime] = useState([]);

    useEffect(() => {

        if (uptimeData) {
            //This will create 3 collections.  1 for each shift
            //and sum the daily uptime across machines.
            const sumOneDate = [];
            const sumTwoDate = [];
            const sumThreeDate = [];
            sumShiftByDate(sumOneDate, "s1hours");
            sumShiftByDate(sumTwoDate, "s2hours");
           // var hasThreeShifts = false;
            if (uptimeData[0]["s3hours"] != null) {
               // hasThreeShifts = true;
                sumShiftByDate(sumThreeDate, "s3hours");
                setSumThreeByDate(sumThreeDate);
            }
            setSumOneByDate(sumOneDate);
            setSumTwoByDate(sumTwoDate);

        }

    }, [uptimeData, selectedMachines]);

    const sumShiftByDate = (sumDate, shiftProp) => {
        const averageDailyUptime = [];
        //get distinct dates
        const uniqueDates = [];
        for (let value of uptimeData) {

            //break this appart from "10/01/20" "month/year"
            var parts = value.date.split('/');
            var monthYear = parts[0] + "/" + parts[2];
            if (uniqueDates.indexOf(monthYear) === -1) {
                uniqueDates.push(monthYear);
            }

        }

        const sumReducer = (accumulator, currentValue) => accumulator + currentValue;
        uniqueDates.forEach((aDate) => {
            //to calculate the daily average at the same time
            //keep track of the daysAboveZero
            let daysAboveZero =[];
            let filterByDate = uptimeData.filter(a => {
                //Needs to take into account the machine selection
                //The issue w/ this is machines that we have monitored in the past but no longer do
                //will not be included in these totals...ugh
                var result = false;
                if (selectedMachines.indexOf(a.machinename) !== -1) {
                    var parts = a.date.split('/');
                    var monthYear = parts[0] + "/" + parts[2];
                    result = monthYear === aDate
                    if (result && daysAboveZero.indexOf(parts[1]) === -1 && (a.s1hours>0 || a.s2hours>0 || a.s3hours>0))
                    {
                        //TODO: should we also consider > 5% utilization?
                        //push the date if 
                        //console.log("adding " + parts[1] + " value " + monthYear);
                        daysAboveZero.push(parts[1])
                    }
                    return result
                }
                return result;
            });
            let hoursForDailyAvg =filterByDate.map(a => (a.s1hours + a.s2hours + (a.s3hours? a.s3hours:0)));
            let hours = filterByDate.map(a => a[shiftProp]);
            let sum = 0;
            let sumavg=0;
            if (hours.length>0)
            {
                sum = hours.reduce(sumReducer);
            }
            if (hoursForDailyAvg.length > 0)
            {
                sumavg = hoursForDailyAvg.reduce(sumReducer);
            }
            //calc the average
            sumavg = sumavg/daysAboveZero.length;
            //round to 0 decimal places
            sumavg = Math.round(sumavg * 100)/100;
            //round to 0 decimal places
            sum = Math.round(sum);
            //console.log("sum hours for date: " + aDate + " " + sum);
            sumDate.push({ date: aDate, sum: sum });
            averageDailyUptime.push({ date: aDate, avg: sumavg });

        });
        setAvgDailyUptime(averageDailyUptime);
    };

    return {
        sumOneByDate,
        sumTwoByDate,
        sumThreeByDate,
        avgDailyUptime
    };


};
export default useUptimeData;