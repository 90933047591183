import React from 'react';
import Proptypes from 'prop-types';
import './UtilThermometerStyles.scss';
import Typography from '@material-ui/core/Typography';


const getFormattedLabel = ({label, insertAfter}, val) => insertAfter ? val + label : label + val;

const getIntervals = (format, max, steps) => {
	let intervals = [];

	for (let step = 0; step <= steps; step++) {
		const val = Math.abs(((max / steps) * step).toFixed(2));
		const percent = (val / max) * 100;
		const interval = {
			percent,
			label: getFormattedLabel(format, val)
		};
		
		intervals.push(interval);
	}

	return intervals;
}

const UtilThermometer = ({format, height, max, size, steps, theme, value, avgutilization}) => {
	const intervals = getIntervals(format, max, steps);
	const percent = value / max * 100;
    const valstr = getFormattedLabel(format, value);
    

	const stepIntervals = intervals.map(({label, percent}, i) => (
		<li key={i} style={{ bottom: `calc(${percent}% - 1px)` }}>
			{label}
		</li>
	));

	const styledHeight = {height: `${height}px`};

	const styledHeightPercent = {height: `${percent}%`};
	
	//this is how we dynamically change the thermometer scale based on historical avg.  but it
	//is not very consistent.
	const high =avgutilization-10;
	const mid =high+15;
	const low =mid;

	const styledHeightBgColor = {height: `calc(${height}px - 57px)`, background:`linear-gradient(#059b37 ${high}%,#e1e432 ${mid}%,#f44336 ${low}%)`};

	return (
		
        <div style={{ marginTop: 10 }}>
			{/* {console.log("Util thermometer avgutilization: " + avgutilization)} */}
        <Typography variant="subtitle1" align="center">
            Machine Utilization
            </Typography>
		<div style={styledHeight} className={`thermometer thermometer--${size} thermometer--theme-${theme}`}>
            
            
			<div className="thermometer__draw-a"></div>
			<div className="thermometer__draw-b"></div>
			<div className="thermometer__meter">
				<ul className="thermometer__statistics">{stepIntervals}</ul>
				<div style={styledHeightPercent} className="thermometer__mercury">
					<div className="thermometer__percent-current">{valstr}</div>
					<div className="thermometer__mask">
						<div className="thermometer__bg-color" style={styledHeightBgColor}></div>
					</div>
				</div>
			</div>
		</div>
        </div>
	);
};

UtilThermometer.propTypes = {
	format: Proptypes.shape({
		label: Proptypes.string,
		insertAfter: Proptypes.bool
	}),
	height: Proptypes.number,
	max: Proptypes.number,
	size: Proptypes.oneOf(['small', 'normal', 'large']),
	steps: Proptypes.number,
	theme: Proptypes.oneOf(['light', 'dark']),
	value: Proptypes.number
};

UtilThermometer.defaultProps = {
	format: {
		label: '%',
		insertAfter: true
	},
	height: 200,
	max: 100,
	size: 'small',
	steps: 4,
	theme: 'light',
	value: 0
};

export default UtilThermometer;