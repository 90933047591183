import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import parse from 'date-fns/parse'
import Production from './Production';
import UtilizationGraph from './UtilizationGraph';
import MachineDetails from './MachineDetails';
import { MyContext } from './MyContext';
import UtilizationGauge from './UtilizationGauge';
import ProductionPerformance from './ProductionPerformance';
import MachineInfo from './MachineInfo';
import OperatorView from './OperatorView';
import UptimeAnalyticsByShift from './AnalyticsUptimeByShift';
import UptimeAnalyticsTotal from './AnalyticsUptimeTotal';
import UptimeAnalyticsDailyAvg from './AnalyticsUptimeDailyAvg';
import UptimeAnalyticsDaily from './AnalyticsUptimeDaily';
import AnalyticsUtilizationByShift from './AnalyticsUtilizationByShift';
import AnalyticsTopMachine from './AnalyticsTopMachine';
import DownTimeTotals from './DownTimeTotals';
import ShopFloorDisplay from './ShopFloorDisplay';
import MachineTimelineList from './MachineTimelineList';
import AnalyticsDowntime from './AnalyticsDowntime';


export const UtilGraphComponent = () => {
    const { data, selectedMachines, setCustomerName, setCurrentView, setCurrentViewTooltip } = useContext(MyContext)
    const { id } = useParams();//this pulls in the url param for the customer

    useEffect(() => {
        console.log("Dashboard useEffect id changed: " + id);
        setCustomerName(id);
        setCurrentView("Machine Utilization")
        setCurrentViewTooltip("View machine efficiencies across your production and identify if production goals are being met.");
    }, [id]);

    return (

        <div>
            <UtilizationGraph data={data} selectedMachines={selectedMachines} />
        </div>
    );

};

export const MachineDetailsComponent = () => {
    const { data, selectedMachines, setCustomerName, setCurrentView, setCurrentViewTooltip } = useContext(MyContext)
    const { id } = useParams();//this pulls in the url param for the customer

    useEffect(() => {
        console.log("Dashboard useEffect id changed: " + id);
        setCustomerName(id);
        setCurrentView("Machine Details");
        setCurrentViewTooltip("Birds-eye view of machine output and utilization across your production.  Click on a Machine Name Header to view the specifics of that machine.");
    }, [id]);

    return (

        <div>
            <MachineDetails data={data} selectedMachines={selectedMachines} />
        </div>
    );

};

export const ProductionComponent = () => {
    const { data, selectedMachines, setCustomerName, setArchiveDate, setCurrentView, setCurrentViewTooltip } = useContext(MyContext)
    const { id } = useParams();//this pulls in the url param for the customer
    const { archivedateshift } = useParams();

    useEffect(() => {

        // console.log("Dashboard useEffect id changed: " + id);
        setCustomerName(id);
        setCurrentView("Machine Uptime")
        if (archivedateshift) {
            //example param 10-27-2020_Tuesday_shift1
            var parts = archivedateshift.split('_');
            console.log("parts[0] " + parts[0]);
            var adate = parse(parts[0], "M-d-yyyy", new Date());
            var shift = parts[2];

            //{ date: selectedDate, shiftName: shiftName }
            setArchiveDate({ date: adate, shiftName: shift });
            //console.log("after setArchiveDate shift " + shift);
        }
        setCurrentViewTooltip("Aggregate machine data provides real-time metrics such as production pace, utilization, and the top/bottom 3 performant machines.");
    }, [id]);

    return <div>

        <Production data={data} selectedMachines={selectedMachines} showTopBottom={true} showUtilization={true} />

    </div>
};

export const UtilGaugeComponent = () => {
    const { data, selectedMachines, setCustomerName, setCurrentView, setCurrentViewTooltip } = useContext(MyContext)
    const { id } = useParams();//this pulls in the url param for the customer

    useEffect(() => {
        console.log("Dashboard useEffect id changed: " + id);
        setCustomerName(id);
        setCurrentView("Machine Utilization")
    }, [id]);

    return <div>
        <UtilizationGauge sitedata={data} selectedMachines={selectedMachines} />

    </div>
};

export const ProductionPerformanceComponent = () => {
    const { data, selectedMachines, setCustomerName, setCurrentView, setCurrentViewTooltip } = useContext(MyContext)
    const { id } = useParams();//this pulls in the url param for the customer

    useEffect(() => {
        //console.log("Dashboard useEffect id changed: " + id);
        setCustomerName(id);
        setCurrentView("Production Performance")
        setCurrentViewTooltip("How are your machines currently performing?  Use this view to catagorize machines by their current performance.  See which machines are idle, performing as expected, exceeding expectations, or struggling to keep up.");
    }, [id]);

    return <div>
        <ProductionPerformance data={data} selectedMachines={selectedMachines} />
    </div>
};
export const MachineInfoComponent = () => {
    const { data, setCurrentView, setCustomerName, dialogReasonCodes, setCurrentViewTooltip } = useContext(MyContext)
    const { id } = useParams();//this pulls in the url param for the customer
    const { machinename } = useParams()
    const [selectedMachine, setSelectedMachine] = useState(null);

    useEffect(() => {
        //console.log("Dashboard.MachineInfoComponent useEffect id changed: " + id);
        setCustomerName(id);

    }, [id]);

    useEffect(() => {
        //console.log("Dashboard dialog reason codes: " + dialogReasonCodes.length);
    }, [dialogReasonCodes]);
    useEffect(() => {
        if (data) {
            //console.log("Dashboard.MachineInfoComponent machinename: " + machinename);
            var currMachineName = (selectedMachine ? selectedMachine.machinename : machinename);
            console.log("Dashboard.MachineInfoComponent machinename: " + selectedMachine?.machinename);

            //we need to select the correct machien from the data

            var machineinfo = data.machines.find(a => a.machinename === currMachineName);
            //console.log("Dashboard.MachineInfoComponent machineinfo.machinename: " + machineinfo.machinename);
            setCurrentView("Machine Details: " + machineinfo.machinename);
            setSelectedMachine(machineinfo);
            setCurrentViewTooltip("Machine specific production hours, utilization performance, and timeline.  Click the red sections of the down time to label it with a reason code.  Comments help also.");


        }
    }, [data,]);

    return <div >
        <MachineInfo machineinfo={selectedMachine} singlemachine={true} />

    </div>
};

export const ShopFloorDisplayComponent = () => {
    const { setOperatorView, setCustomerName, setCurrentViewTooltip } = useContext(MyContext)
    const { id } = useParams();//this pulls in the url param for the customer
    const { displayname } = useParams();


    useEffect(() => {
        //console.log("Dashboard.ShopFloorDisplayComponent useEffect id changed: " + id);
        setCustomerName(id);
        setOperatorView(true);

    }, [id]);

    return <div >
        <ShopFloorDisplay displayConfigId={displayname} />
    </div>
}

export const OperatorViewComponent = ({ isOperatorView }) => {
    const { data, setCurrentView, customerName, setCustomerName, dialogReasonCodes, setOperatorView, setCurrentViewTooltip } = useContext(MyContext)
    const { id } = useParams();//this pulls in the url param for the customer
    const { machinename } = useParams();
    const [selectedMachine, setSelectedMachine] = useState([]);

    useEffect(() => {
        console.log("Dashboard.OperatorViewComponent useEffect id changed: " + id);
        setCustomerName(id);
    }, [id]);

    useEffect(() => {
        //console.log("Dashboard dialog reason codes: " + dialogReasonCodes.length);
    }, [dialogReasonCodes]);
    useEffect(() => {
        if (data) {
            console.log("Dashboard.OperatorViewComponent customerName: " + customerName);
            //we need to select the correct machien from the data
            var machineinfo = data.machines.find(a => a.machinename === machinename);
            //At the beginning of the shift the MUT might not have submitted data yet and this
            //can be null so only set it if we have it
            if (machineinfo) {
                //console.log("Dashboard.MachineInfoComponent machineinfo.machinename: " + machineinfo.machinename);
                setCurrentView("OperatorView: " + machineinfo.machinename);
                setSelectedMachine(machineinfo);
                setOperatorView(isOperatorView);
            }

        }
    }, [data, machinename]);

    return <div>
        <OperatorView machineinfo={selectedMachine} />

    </div>
};

export const UptimeAnalyticsByShiftComponent = () => {
    const { id } = useParams();//this pulls in the url param for the customer
    const { setCustomerName, setCurrentView, setCurrentViewTooltip } = useContext(MyContext)

    useEffect(() => {
        console.log("Dashboard.UptimeAnalyticsComponent useEffect id changed: " + id);
        setCustomerName(id);
        setCurrentView("Analytics - Shift Uptime");
        setCurrentViewTooltip("View month over month uptime by shift.");

    }, [id]);



    return <div >
        <UptimeAnalyticsByShift />

    </div>
};

export const UptimeAnalyticsTotalComponent = () => {
    const { id } = useParams();//this pulls in the url param for the customer
    const { setCustomerName, setCurrentView, setCurrentViewTooltip } = useContext(MyContext)

    useEffect(() => {
        console.log("Dashboard.UptimeAnalyticsTotalComponent useEffect id changed: " + id);
        setCustomerName(id);
        setCurrentView("Analytics - Monthly Uptime");
        setCurrentViewTooltip("Aggragate montly uptime across all prodction shifts.  Use the Daily Average graph below to know the average daily uptime each month.  This will help identify the productivity of the current month and how it compares to the previous.");
    }, [id]);



    return <div >
        <UptimeAnalyticsTotal showUptime={false} />
        <UptimeAnalyticsDailyAvg />

    </div>
};

export const UptimeAnalyticsDailyComponent = () => {
    const { id } = useParams();//this pulls in the url param for the customer
    const { setCustomerName, setCurrentView, setCurrentViewTooltip } = useContext(MyContext)

    useEffect(() => {
        console.log("Dashboard.UptimeAnalyticsDailyComponent useEffect id changed: " + id);
        setCustomerName(id);
        setCurrentView("Analytics - Daily Totals");
        setCurrentViewTooltip("Daily uptime totals broken down by shift in a stacked bar chart visual. Unstack if you prefer a side-by-side comparison.");
    }, [id]);



    return <div >
        <UptimeAnalyticsDaily />
    </div>
};


export const UtilizationAnalyticsByShiftComponent = () => {
    const { id } = useParams();//this pulls in the url param for the customer
    const { setCustomerName, setCurrentView, setCurrentViewTooltip } = useContext(MyContext)

    useEffect(() => {
        console.log("Dashboard.UtilizationAnalyticsByShiftComponent useEffect id changed: " + id);
        setCustomerName(id);
        setCurrentView("Analytics - Shift Utilization");
        setCurrentViewTooltip("Machine Utilization is an important measure of production efficiency.  Use this view to know if utilizatoin is increasing as unexpected down times are being reduced.");
    }, [id]);



    return <div >
        <AnalyticsUtilizationByShift />

    </div>
};

export const AnalyticsTopMachineComponent = () => {
    const { id } = useParams();//this pulls in the url param for the customer
    const { setCustomerName, setCurrentView, setCurrentViewTooltip } = useContext(MyContext)

    useEffect(() => {
        console.log("Dashboard.AnalyticsTopMachineComponent useEffect id changed: " + id);
        setCustomerName(id);
        setCurrentView("Analytics - Shift Top Machines");
        setCurrentViewTooltip("Ever wonder which machines make the most chips and during which shift?  Select a shift from the monthly totals and see the rankings in the graph below.");
    }, [id]);

    return <div ><AnalyticsTopMachine /></div>
};
export const AnalyticsDowntimeReportComponent = () => {
    const { id } = useParams();//this pulls in the url param for the customer
    const { setCustomerName, setCurrentView, setCurrentViewTooltip } = useContext(MyContext)

    useEffect(() => {
        console.log("Dashboard.AnalyticsDowntimeReportComponent useEffect id changed: " + id);
        setCustomerName(id);
        setCurrentView("Analytics - Down Time Report");
        setCurrentViewTooltip("View the major impediments to your production with this report. Now collaborate with your team to work to reduce them.");
    }, [id]);

    return <div ><AnalyticsDowntime /></div>
};

export const DowntimeTotalComponent = () => {
    const { id } = useParams();//this pulls in the url param for the customer
    const { setCustomerName, setCurrentView, data, selectedMachines, setCurrentViewTooltip } = useContext(MyContext)

    useEffect(() => {
        console.log("Dashboard.DowntimeTotalComponent useEffect id changed: " + id);
        setCustomerName(id);
        setCurrentView("Down Time Totals");
        setCurrentViewTooltip("Labeling down times is important to identify lost production.  Use this view to label down times and see which down times impede your production the most.");
    }, [id]);

    return (
        <div>
            <DownTimeTotals />
            <MachineTimelineList data={data} selectedMachines={selectedMachines} />
        </div>
    );
};


