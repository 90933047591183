import React, { useState, useEffect,  } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip,  Cell, LabelList, Text } from 'recharts'
import MachineDetails from './MachineDetails';
import {colorBlueAlpha, colorGreenAlpha, colorYellowAlpha, colorRedAlpha} from './UtilizationGraph';



const ProductionPerformance = ({ data, selectedMachines}) => {

  const [sidle, setIdle] = useState([]);
  const [sexcel, setExcel] = useState([]);
  const [sperform, setPerform] = useState([]);
  const [sopportunity, setOpportunity] = useState([]);
  const [sperfData, setPerfData] = useState([]);
  const [sShowDetails, setShowDetails] = useState([]);

  useEffect(() => {

    if (data) {
      initMachineStatusChart();
     
    }

  }, [data, sShowDetails, selectedMachines]);

  const initMachineStatusChart = () => {
    //figure out the count for each segment of the pie
    var idleCount = 0;
    var excelCount = 0;
    var performCount = 0;
    var opportunityCount = 0;
    var idleMachines = [];
    var excelMachines = [];
    var performMachines = [];
    var opportunityMachines = [];
    //var selectedMachinesCount=0;
    //var machinecount = 0;
    //var colorObj = { value: this.colorGreen };
    for (var i = 0; i < data.machines.length; i++) {
      if (selectedMachines.indexOf(data.machines[i].machinename) !== -1) {
        //selectedMachinesCount++;
        //this.totaluptimeminutes += data.machines[i].uptimeminutes;
        //we want to include 0 into this calculation for how we are using it
        //to set an average line
        if (data.machines[i].averageuptimepercent >= 0) {
          //this.historicalAvgUtilization += data.machines[i].averageuptimepercent;
          // machinecount++;
        }
        if (shouldDisplayTimeline('idle', data.machines[i].currentuptimepercent, data.machines[i].averageuptimepercent)) {
          //for idle machines we calculate the lost opportunity by using the avg times elapse minuts in the shift.
          idleCount += (data.machines[i].averageuptimepercent * data.shiftelapsemins) / 100;
          idleMachines.push(data.machines[i].machinename);

        }
        if (shouldDisplayTimeline('excelling', data.machines[i].currentuptimepercent, data.machines[i].averageuptimepercent)) {
          excelCount += data.machines[i].uptimeminutes;
          excelMachines.push(data.machines[i].machinename);
        }
        if (shouldDisplayTimeline('performing', data.machines[i].currentuptimepercent, data.machines[i].averageuptimepercent)) {
          performCount += data.machines[i].uptimeminutes;
          performMachines.push(data.machines[i].machinename);
        }
        if (shouldDisplayTimeline('opportunity', data.machines[i].currentuptimepercent, data.machines[i].averageuptimepercent)) {
          opportunityCount += data.machines[i].uptimeminutes;
          opportunityMachines.push(data.machines[i].machinename);
        }
      }
    }
    //convert to hours
    idleCount /= 60;
    excelCount /= 60;
    performCount /= 60;
    opportunityCount /= 60;

    //setup the arrays of machine names to display the details of when a bar is selected
    setIdle(idleMachines);
    setPerform(performMachines);
    setExcel(excelMachines);
    setOpportunity(opportunityMachines);

    setPerfData([
      { name: 'Idle', hours: Math.round(idleCount * 10) / -10},
      { name: 'Opportunity', hours: Math.round(opportunityCount * 10) / 10 },
      { name: 'Performing', hours: Math.round(performCount * 10) / 10},
      { name: 'Excel', hours: Math.round(excelCount * 10) / 10},
    ]);
    

  }

  const shouldDisplayTimeline = (category, currentUptime, avgUpTime) => {
    //console.log("shouldDisplayTimeline: category " + category);
    if ('idle' === category.toLowerCase()) {
      //if the machine is Idle then it's current percentage is <= 5%
      // console.log("shouldDisplayTimeline() displaying idle");
      if (currentUptime <= 5) {
        //color.value = this.colorBlue;
        return true;
      }
    }
    else if ('opportunity' === category.toLowerCase()) {
      //if the machine is an opportunity then is's current percentage is more than 10% below average
      // console.log("shouldDisplayTimeline() displaying opportunity");
      if (currentUptime > 5 && currentUptime < (avgUpTime - 10)) {
        //color.value = this.colorYellow;
        return true;
      }

    }
    else if ('excelling' === category.toLowerCase()) {
      //if the machine is excelling then is's current percentage is more than 5% above average
      // console.log("shouldDisplayTimeline() displaying opportunity");
      if (currentUptime > (avgUpTime + 5)) {
        //color.value = this.colorGreen;
        return true;
      }
    }
    else if ('performing' === category.toLowerCase()) {
      //if the machine is performing then it's current percentage is >= 10% below average and <= 5% above average
      var aboveAvg = Math.min(avgUpTime + 5, 101);
      var belowAvg = Math.max(avgUpTime - 10, 5);//want to be above 5% (the idle percentage) not 0%
      if (currentUptime >= belowAvg && currentUptime <= aboveAvg) {
        //color.value = this.colorYellow;
        return true;
      }
    }

    return false;
  }

  const handlePerfClick = (e) => {
    console.log("handlePerfClick: " + e.name + " sidle.length: " + sidle[0]);
    if ("idle" === e.name.toLowerCase()) {
      setShowDetails(sidle);
    }
    if ("opportunity" === e.name.toLowerCase()) {
      setShowDetails(sopportunity);
    }
    if ("performing" === e.name.toLowerCase()) {
      setShowDetails(sperform);
    }
    if ("excel" === e.name.toLowerCase()) {
      setShowDetails(sexcel);
    }
  };

  return (
    <div>
      <BarChart width={1000} height={500} data={sperfData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }} >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis label={
   <Text x={0} y={0} dx={25}dy={300} offset={0} angle={-90} >Machine Uptime</Text>
}/>
        <Tooltip />
        
        <Bar dataKey="hours"  onClick={handlePerfClick} isAnimationActive={false}>
        <LabelList dataKey="hours" position="middle" fill="white" />
        {sperfData?.map((entry, index) => (
          // need to dynamically set the color of the bars based on the type of performance
            <Cell fill={  entry.name==="Idle" ? colorBlueAlpha
            : entry.name==="Opportunity" ? colorRedAlpha
            :entry.name==="Performing" ? colorYellowAlpha
            :entry.name==="Excel"? colorGreenAlpha : '#000000'}/>
        ))}
        </Bar>

      </BarChart>
      
      <MachineDetails data={data} selectedMachines={sShowDetails} />
    </div>
  );
}

export default ProductionPerformance;