import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";



export const colorYellow = '#ff9900';
export const colorRed = '#dc3912';
export const colorGreen = '#109618';
export const colorBlue = '#3366cc';
export const alphaValue = '99';
export const colorYellowAlpha = colorYellow + alphaValue;
export const colorRedAlpha = colorRed + alphaValue;
export const colorGreenAlpha = colorGreen + alphaValue;
export const colorBlueAlpha = colorBlue + alphaValue;
//based on the performance, we provide a color to display.
export const getObjectPerformanceColor = (currentUptime, avgUpTime) => {

    // console.log("getObjectPerformanceColor: category ");
    if (currentUptime <= 5) {
        return colorBlue;
    }
    if (currentUptime > 5 && currentUptime < (avgUpTime - 10)) {
        return colorRed;
    }
    if (currentUptime > (avgUpTime + 5)) {
        return colorGreen;
    }
    //console.log("getObjectPerformanceColor: 2 ");

    // console.log("getObjectPerformanceColor: 3 ");
    //if the machine is performing then it's current percentage is >= 10% below average and <= 5% above average
    var aboveAvg = Math.min(avgUpTime + 5, 101);
    var belowAvg = Math.max(avgUpTime - 10, 5);//want to be above 5% (the idle percentage) not 0%
    //console.log("aboveAvg: " + aboveAvg);
    // console.log("belowAvg: " + belowAvg);
    if (currentUptime >= belowAvg && currentUptime <= aboveAvg) {
        return colorYellow;
    }
    return colorBlue;
}
const UtilizationGraph = ({ data, selectedMachines, width = "100%", height = "600px", pGroupWidth = "90%", showUIDetails = true, isShopFloorDisplay=false }) => {


    const [sUtilizationBarChartOptions, setUtilizationBarChartOptions] = useState(null);
    const [sUtilizationData, setUtilizationData] = useState(null);
    

    useEffect(() => {

        if (data) {
            // console.log("UtilizationGraph useEffect data");
            drawGraph();
        }

    }, [data, selectedMachines]);

    const drawGraph = () => {


        //init these shared properties
        var totaluptimeminutes = 0;
        var selectedMachinesCount = 0;
        var avgUtilization = 0;
        var histAvgUtilization = 0;
        var avgCount = 0;
        var histAvgCount = 0;
        let _utilizationdata = [];//any[];//[] = new Array<any>();
        //push the init data for the column chart
        //   console.log("utilizationdata.lenght: " + this.utilizationdata.length);
        //_utilizationdata.push(['Percentage', 'Target Capacity', { role: "style" }, { role: 'annotation' }, 'Capacity', { role: "style" }, { role: 'annotation' }]);
        _utilizationdata[0] = ['Percentage', 'Target Utilization', { role: "style" }, { role: 'annotation' }, 'Utilization', { role: "style" }, { role: 'annotation' }];
        //  console.log("utilizationdata.lenght: " + _utilizationdata.length);
        //we create a second series of data that's why there are two 'Capacity' headers so we can label the
        //right side of the graph w/ vaxes array of options.
        //sort by uptimeminutes of each machine
        //if (this.customer == 'Divfit'|| this.customer == 'Bowden'|| this.customer == 'Teco' || this.customer == 'Troy' || this.customer == 'Nelson') 
        
        var sortedData=JSON.parse(JSON.stringify(data));
        sortedData.machines.sort(function (a, b) { return a.uptimeminutes - b.uptimeminutes });
            // console.log("shopfloordisplay sorted for all");
        
        for (var i = 0; i < sortedData.machines.length; i++) {
            //test if this machine is selected
            if (selectedMachines.indexOf(sortedData.machines[i].machinename) != -1) {
                // console.log("shopfloordisplay.component drawgraph() this.sitedata.machines[i].customersethide: " + sortedData.machines[i].customersethide)
                if (sortedData.machines[i].customersethide) {
                    //the customer entered <0 for the mps data for this machine
                    //which means they do not want it displayed in the shop floor display
                    //we only honor this when this is a shop floor display.  
                    if (false)//TODO(!this.showNavigationMenu)
                    {
                        //if we are not showing navigation then this
                        //is a shop floor display
                        // console.log("shopfloordisplay.component drawgraph() not showing: " + data.machines[i].machinename);
                        continue;

                    }
                }
                selectedMachinesCount++;
                var uptimeMins = sortedData.machines[i].uptimeminutes;
                totaluptimeminutes += uptimeMins;
                var perfcolor = getObjectPerformanceColor(sortedData.machines[i].currentuptimepercent, sortedData.machines[i].averageuptimepercent);
                //if the machine is less than 5% then we elevate the column to a min height of 5 so it can still be seen
                var colHeight = Math.max(2, sortedData.machines[i].currentuptimepercent);
                //console.log("colHeight: " + colHeight);
                //adjust for the percentage
                colHeight = colHeight / 100;
                //calculate the uptime hours for display on the individual column
                uptimeMins /= 60.0;
                //round it so it fit
                uptimeMins = Math.round(uptimeMins * 10) / 10;
                var columnlabel = uptimeMins + 'hrs';//this.sitedata.machines[i].currentuptimepercent + '%';
                //only display uptimeMins if they are greater than 0 and the machine is not idle i.e. > 5% utilization
                // if (uptimeMins > 0 && this.sitedata.machines[i].currentuptimepercent > 5) {
                //    columnlabel += '\n' + uptimeMins;
                //}
                //5/10/2019 adding support for part count
                var partCount = sortedData.machines[i].partcount;
                if (partCount > 0) {
                    //ad this to our column label
                    columnlabel += '\n' + partCount;
                }
                var targetCapacity = sortedData.machines[i].targetuptimepercent;

                //adjust for the percentage
                targetCapacity = targetCapacity / 100;
                var targetUptimeMins = sortedData.machines[i].targetuptimeminutes / 60.0; //display in hours
                //round it so it fit
                targetUptimeMins = Math.round(targetUptimeMins * 10) / 10;
                var targetCapacityLabel = targetUptimeMins + 'hrs';//2.5hrs';
                var targetOpacity = .4;
                if (targetCapacity == 0) {
                    //if we do not have a targetCapacity for this machine
                    //then we do not want to display anything on the grid.
                    targetOpacity = 0;
                    targetCapacityLabel = '';
                }
                if (targetCapacity <= colHeight) {
                    //this is the case where the machine is exceeding the/
                    //the expected capacity.  Therefore it looks a little cluttered
                    //with the text of the expected capacity;
                    targetCapacityLabel = '';
                }

                if (showUIDetails) {
                    //push the machine data to create a bar for this machine.  we are adding the 0 column height to have labels on the left and right side of the graph
                    _utilizationdata.push([sortedData.machines[i].machinename, targetCapacity, 'color:purple; opacity: ' + targetOpacity, targetCapacityLabel, colHeight, 'color:' + perfcolor + '; opacity: 0.7', columnlabel]);
                }
                else {
                    //this is the case where the machine details are being shown for an individual machine
                    _utilizationdata.push(["cur", targetCapacity, 'color:purple; opacity: ' + targetOpacity, targetCapacityLabel, colHeight, 'color:' + perfcolor + '; opacity: 0.7', columnlabel]);
                    //so we add the average bar as well
                    var avgColHeight = Math.max(2, sortedData.machines[i].averageuptimepercent);
                    //console.log("colHeight: " + colHeight);
                    //adjust for the percentage
                    avgColHeight = avgColHeight / 100;
                    _utilizationdata.push(["avg", '', 'color:purple; opacity: ' + 0, '', avgColHeight, 'color:' + 'purple' + '; opacity: 0.7', '45D avg']);


                }
                //gather utilization data to calculate current and historical average
                if (sortedData.machines[i].averageuptimepercent > 0) {
                    histAvgUtilization += sortedData.machines[i].averageuptimepercent;
                    histAvgCount++;
                }
                if (sortedData.machines[i].currentuptimepercent > 5) {
                    avgUtilization += sortedData.machines[i].currentuptimepercent;
                    avgCount++;
                }
            }
        }

        //all the selected machine data has been processed, create the data for the chart
        var utilizationdata = _utilizationdata;// this.createDataTable(_utilizationdata);
        //we do not have to create a view for this but nice feature to know about.
        //instead we set series to stacked to get the desired output
        ///var view = this.createDataView(this.utilizationdata);
        //view.setColumns([0,1,2,3,4]); //here you set the columns you want to display.  We don't want to display the dummy column we added to get 2 vertical axes
        //calculate our current and historical utilization averages
        if (avgUtilization > 0) {
            avgUtilization /= avgCount;
            avgUtilization = Math.trunc(avgUtilization);
        }
        if (histAvgUtilization > 0) {
            histAvgUtilization /= histAvgCount;
            histAvgUtilization = Math.trunc(histAvgUtilization);
        }

        //use avgUtil and histAvgUtil to change the color of the title text
        var perfcolor = getObjectPerformanceColor(avgUtilization, histAvgUtilization);
        //this will set the timeline header color
        var timelineheaderstyle = perfcolor;
        // if (this.displayingTimeline) {
        //     var prefix = '';
        //     if (perfcolor == this.colorGreen) {
        //         prefix = '+';
        //     }
        //     if (perfcolor == this.colorRed) {
        //         prefix = '-';
        //     }

        //     var timeshiftDisplayText = prefix + this.timeshiftDisplayText;
        // }
        //convert our total uptime minutes into hours.
        //this will be our numerator
        var totalUptimeHours = totaluptimeminutes / 60.0;

        //round to 1 decimal places
        totalUptimeHours = Math.round(totalUptimeHours * 10) / 10;
        //increase font size so it's readable from far distance
        var titleTextFontSize = 14;
        var titleText = 'Average Utilization ' + avgUtilization + '%';
        var timelineavgutil = titleText + ' -- Total Uptime ' + totalUptimeHours + 'hrs'; //this is specific to displaying the machine timelines
        if (isShopFloorDisplay || !showUIDetails) {
            //if we are not displaying the navigation menu
            //then we assume this is an on floor MUT display unit so we need large fontt size
            titleTextFontSize = 36;
            //and we put a cariage return to help w/ the larger font
            titleText += '\nTotal Uptime ' + totalUptimeHours + 'hrs';
        }
        else {
            titleText += ' Total Uptime ' + totalUptimeHours + 'hrs';
        }

        var utilizationobarchartptions = {
            isStacked: false, //we set is stack so the dummy column doesn't take up space and reduce the width of the column
            //fontSize: 24,
            titleTextStyle: (showUIDetails ? { color: perfcolor, fontSize: titleTextFontSize } : {}),
            //backgroundColor:{stroke: this.colorGreen},
            title: (showUIDetails ? titleText : "Machine Utilization"),
            series: {
                0: { targetAxisIndex: 0, type: "scatter", pointShape: 'star', pointSize: (showUIDetails ? 20 : 10) },
                1: { targetAxisIndex: 1 },

            },
            vAxes: {

                0: {
                    minValue: 0,
                    //ticks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                    ticks: [0, .1, .2, .3, .4, .5, .6, .7, .8, .9, 1],
                    format: '#,###%',
                    minorGridlines: { count: 1 },

                },
                1: {
                    minValue: 0,
                    //ticks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                    ticks: [0, .1, .2, .3, .4, .5, .6, .7, .8, .9, 1],
                    format: '#,###%',
                    minorGridlines: { count: 1 },

                }
            },
            // bar: { groupWidth: pGroupWidth, width: 125 },
            legend: { position: "none" },
            hAxis: {
                titleTextStyle: {
                    color: perfcolor,
                    fontSize: titleTextFontSize
                },
                title: (showUIDetails) ? data.title : "" //don't show the title if we are not showing 
            }
        };
        if (!showUIDetails) {
            utilizationobarchartptions.vAxes[1] = {};
            utilizationobarchartptions.series[1] = {};
        }
        setUtilizationBarChartOptions(utilizationobarchartptions);
        setUtilizationData(utilizationdata);


    }




    return (
        <Chart
            width={width}
            height={height}
            chartType="ColumnChart"
            loader={<div>Loading...</div>}
            data={sUtilizationData}
            options={sUtilizationBarChartOptions}
        // For tests
        //   rootProps={{ 'data-testid': '3' }}
        />
    );
}


export default UtilizationGraph