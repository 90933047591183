import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from './MyContext';
import { getObjectPerformanceColor } from './UtilizationGraph';
import Production from './Production';
import UtilizationGraph from './UtilizationGraph';
import MachineTimeline from './MachineTimeline';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ScrapCount from './ScrapCount';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));
const MachineInfo = ({ machineinfo, singlemachine = false }) => {
    const classes = useStyles();
    const [color, setColor] = useState('#3366cc')
    const [selectThisMachine, setSelectThisMachine] = useState([]);
    const { data, selectedMachines, setCurrentView } = useContext(MyContext);
    const [thismachineinfo, setMachineInfo] = useState(machineinfo);
    
    useEffect(() => {
        if (machineinfo) {
            setMachineInfo(machineinfo);
        }
    }, [machineinfo])

    useEffect(() => {
        if (thismachineinfo) {
            var perfcolor = getObjectPerformanceColor(thismachineinfo.currentuptimepercent, thismachineinfo.averageuptimepercent);
            setColor(perfcolor);
            setSelectThisMachine([thismachineinfo.machinename]);
           // setCurrentView("Machine Details: " + mymachineinfo.machinename);
        }


    }, [thismachineinfo])
    

    const getNextMachineName =(currMachineName, delta)=>{
        var nextMachine = null;
        selectedMachines.map( (aMachineName, index)=>{
            //console.log("found machineName: " + aMachineName + " index: " + index);
            if (currMachineName == aMachineName)
            {
                var nextIndex = 0;
                if (delta > 0) //moving forward in the list
                {
                    nextIndex = (index+delta >= selectedMachines.length) ? 0 : index+delta;
                }
                else
                {
                    //moving backwards in the list
                    nextIndex = (index+delta < 0) ? selectedMachines.length-1 : index+delta;
                }
                nextMachine = selectedMachines[nextIndex];
               //console.log("found machineName: " + nextMachine + " index: " + nextIndex);
                
            }
        });
        //update the view name
        setCurrentView("Machine Details: " + nextMachine);
        return nextMachine;
    }

    const handleNextMachine = () => () => {
        var nextMachineName = getNextMachineName(selectThisMachine, 1);
       //we have the name of the next machine in the list, next
       //we have to find it's record in the data
       var nextMachineInfo = data.machines.find(a => a.machinename == nextMachineName);
       setMachineInfo(nextMachineInfo);
    };
    const handlePrevMachine = () => () => {
        //TODO
        console.log("MachineInfo.handlePrevMachine");
        var nextMachineName = getNextMachineName(selectThisMachine, -1);
        //we have the name of the next machine in the list, next
        //we have to find it's record in the data
        var nextMachineInfo = data.machines.find(a => a.machinename == nextMachineName);
        setMachineInfo(nextMachineInfo);
    };
    return (
        <div style={singlemachine ? { display: "inline-flex", height:'100%' } : {}}>
            {singlemachine ?
                <Button style={{ maxWidth: '33px', maxHeight: '100%', minWidth: '35px', minHeight: '30px' }} color="inherit" onClick={handlePrevMachine()} >
                    <ArrowBackIosIcon />
                </Button> : <div></div>}

            <Paper className={classes.paper}>
                <div style={{ background: color }}>
                    <Link style={{ color: '#FFF' }} to={'/start/' + data?.customer + '/machineinfo/' + thismachineinfo?.machinename}>
                        <label>{thismachineinfo?.machinename}</label>
                    </Link>
                    <label style={{ color: "white" }}>{thismachineinfo?.machinenamealias  ? ": " + thismachineinfo?.machinenamealias : ""}</label>
                    <label style={{ color: "white" }}>{thismachineinfo?.partcount > 0 ? " Part Count: " + thismachineinfo?.partcount : ""}</label>
                </div>
                <div style={{ display: "inline-flex" }}>
                    <Production data={data} selectedMachines={selectThisMachine} width={singlemachine ? 600 : 300} height={singlemachine ? 400 : 200} showXTitle={false} showDots={false} showTopBottom={false} />
                    <UtilizationGraph data={data} selectedMachines={selectThisMachine} width={singlemachine ? 250 : 125} height={singlemachine ? 410 : 210} pGroupWidth={"9%"} showUIDetails={false} />
                    {/* <ScrapCount machineinfo={thismachineinfo}/> */}
                </div>
                <div>
                    {singlemachine ?
                        <div>
                            <MachineTimeline machineNames={selectThisMachine} />
                        </div> : <div></div>}
                </div>
            </Paper>
            {singlemachine ?
                <Button style={{ maxWidth: '33px', maxHeight: '100%', minWidth: '35px', minHeight: '30px' }} color="inherit" onClick={handleNextMachine()}>
                    <ArrowForwardIosIcon />
                </Button> : <div></div>}
        </div>
    );

}
export default MachineInfo