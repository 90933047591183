import React, { useState, useEffect, useContext } from 'react';
import { BarChart, Bar, ResponsiveContainer, LabelList, Brush, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { MyContext } from './MyContext';
import useTopMachineData from './useTopMachineData';

const AnalyticsTopMachine = () => {
    const { uptimeData, selectedMachines } = useContext(MyContext)
    const { sumOneByDate, sumTwoByDate, sumThreeByDate } = useTopMachineData();
    const [selectedMonthYear, setSelectedMonthYear] = useState([]);
    const [totalHours, setTotal] = useState([]);
    const [selectedDate, setSelectedDate] = useState("");


    useEffect(() => {

        if (sumOneByDate.length>0)
        {
            //init to current month of first shift
            handleShiftBarClick({date:sumOneByDate[sumOneByDate.length-1].date, shift:'Shift 1', shiftData:sumOneByDate});
        }

        var hasThreeShifts = false;
        if (sumThreeByDate != null) {
            hasThreeShifts = true;
        }
        //consolidate the shift data into 1 array with elements that have a value for each shift
        const totalHrs = [];
        for (var i = 0; i < sumOneByDate.length; i++) {
            if (hasThreeShifts) {
                totalHrs.push({ date: sumOneByDate[i].date, shift1: sumOneByDate[i].sum, shift2: sumTwoByDate[i].sum, shift3: sumThreeByDate[i].sum })
            }
            else {
                totalHrs.push({ date: sumOneByDate[i].date, shift1: sumOneByDate[i].sum, shift2: sumTwoByDate[i].sum })
            }
        }
        setTotal(totalHrs);

    }, [sumOneByDate, sumTwoByDate, sumThreeByDate]);

    const handleShiftBarClick = ({date, shift, shiftData}) => {
        console.log("handleFirstShiftBarClick val: " + date);
        if (shiftData.length > 0) {
            var result = shiftData.filter(a => a.date === date)
            setSelectedMonthYear(result[0].machinetotals);
            setSelectedDate(result[0].date + " " + shift + " ");
        }
    }

   const getVal = (val, machine) => {
        console.log("val: " + val);
        var result = val.machinetotals.filter(a => a.machinename == machine);
        if (result.length > 0) {
            return result[0].total;
        }
    }

    return (
        <div  >
            <h4>Monthly Total by Shift</h4>
            <ResponsiveContainer width={"100%"} height={200} >
                <BarChart
                    data={totalHours}

                    margin={{
                        top: 10, right: 30, left: 0, bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip offset={30} />
                    <Bar type="monotone" dataKey="shift1" stroke="#8884d8" fill="#8884d8" onClick={(event)=>handleShiftBarClick({date:event.date, shift:'Shift 1', shiftData:sumOneByDate})}>

                    </Bar>
                    <Bar type="monotone" dataKey="shift2" stroke="#4442d2" fill="#4442d2" onClick={(event)=>handleShiftBarClick({date:event.date, shift:'Shift 2', shiftData:sumTwoByDate})}>

                    </Bar>
                    {sumThreeByDate ? <Bar type="monotone" dataKey="shift3" stroke="#00ffd2" fill="#00ffd2" onClick={(event)=>handleShiftBarClick({date:event.date, shift:'Shift 3', shiftData:sumThreeByDate})}>

                    </Bar> : <div></div>}
                    <Brush/>
                </BarChart>
            </ResponsiveContainer>
            <h4>{selectedDate} Top Machines</h4>
            <ResponsiveContainer width={"100%"} height={300} >
                
                <BarChart
                    data={selectedMonthYear}

                    margin={{
                        top: 5, right: 30, left: 0, bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="machinename" />
                    <Tooltip />
                    <Bar type="monotone" dataKey="total" stroke="#8884d8" fill="#3366cc" >
                        <LabelList dataKey="total" position="insideTop" fill="#ffffff" />
                    </Bar>
                    <YAxis />
                    {/* <Bar type="monotone"  dataKey="machinetotals[0].total" stroke="#8884d8" fill="#8884d8" /> */}
                    {/* {selectedMachines.map( (machine) =>(
                    console.log("machine.machinename: " + machine),
                <Bar type="monotone"  dataKey={val => getVal(val, machine)} name={machine}  stroke="#8884d8" fill="#8884d8" />
                ))} */}

                   
                </BarChart>
            </ResponsiveContainer>

        </div>
    );
};
export default AnalyticsTopMachine;