import React, { useContext } from 'react';
import Switch from 'react-switch';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { FaTachometerAlt, FaGripfire, FaAddressCard, FaSignal, FaTools } from 'react-icons/fa';
import { IoMdAnalytics } from "react-icons/io"; 
import { MyContext } from './MyContext';
import {colorBlue, colorGreen, colorYellow, colorRed} from './UtilizationGraph';

const history = createBrowserHistory();
const Aside = ({ image, collapsed, rtl, toggled, handleToggleSidebar, handleCollapsedChange }) => {
 
  const { customerName } = useContext(MyContext)

  return (
    <ProSidebar
      image={false}
      rtl={rtl}
      collapsed={!collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div className="block"
          style={{
            paddingTop: '12px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >

          <Switch

            height={16}
            width={30}

            checkedIcon={false}
            uncheckedIcon={false}
            onChange={handleCollapsedChange}
            checked={collapsed}
            onColor="#219de9"
            offColor="#bbbbbb"
          />
          <span>{customerName}</span>
        </div>
        

      </SidebarHeader>

      <SidebarContent>

        <Menu iconShape="circle">
          <MenuItem
            icon={<FaGripfire />}
          // suffix={<span className="badge red">{intl.formatMessage({ id: 'new' })}</span>}
          >
            Machine Uptime<Link to={'/start/' + customerName  }></Link>
          </MenuItem>
          {/* <MenuItem icon={<FaTachometerAlt />}>
            Util Gauge<Link to={'/utilgauge/' + customerName}></Link>
          </MenuItem> */}
          <MenuItem icon={<FaTachometerAlt />}>
            Production Performance<Link to={'/start/' + customerName + '/prodperf'}></Link>
          </MenuItem>
          <MenuItem icon={<FaSignal />}>
            Machine Utilization<Link to={'/start/' + customerName + '/utilgraph'} onClick={() => history.push('/start/' + customerName + '/utilgraph')}></Link>
          </MenuItem>
          <MenuItem icon={<FaTools/>}>
            Production Down Time<Link to={'/start/' + customerName + '/downtimetotals'}></Link>
          </MenuItem>
          <MenuItem icon={<FaAddressCard />}>
            Machine Details<Link to={'/start/' + customerName + '/machinedetails'}></Link>
          </MenuItem>
          <SubMenu title="Production KPIs" icon={<IoMdAnalytics />}>
          <MenuItem>
            Monthly Utilization<Link to={'/start/' + customerName + '/analyticsutilbyshift/' }></Link>
          </MenuItem>
          <MenuItem>
            Top Machine<Link to={'/start/' + customerName + '/analyticstopmachine/'}></Link>
          </MenuItem>
          <SubMenu title="Uptime" icon={<IoMdAnalytics />}>
          <MenuItem>
            Monthly<Link to={'/start/' + customerName + '/analyticstotal'}></Link>
          </MenuItem>
          <MenuItem >
            By Shift <Link to={'/start/' + customerName + '/analyticsbyshift'}></Link>
          </MenuItem>
          <MenuItem >
            Daily<Link to={'/start/' + customerName + '/analyticsdaily/'}></Link>
          </MenuItem>
          </SubMenu>
          <MenuItem>
          Down Time Report<Link to ={'/start/' + customerName + '/analyticsdowntime'}></Link>
          </MenuItem>
          </SubMenu>

        </Menu>
        
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center' }}>
      <div>
          Color Key
          <div style={{background:colorGreen}}>Excelling</div>
          <div style={{background:colorYellow}}>Performing</div>
          <div style={{background:colorRed}}>Opportunity</div>
          <div style={{background:colorBlue}}>Idle</div>
        </div>

        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          <a
            href="http://www.mutcloud.com"
            target="_blank"
            className="sidebar-btn"
            rel="noopener noreferrer"
          >

            <span> E SOLUTIONS</span>
          </a>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
