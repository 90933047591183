import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MachineTimeline from './MachineTimeline';

//for machine


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: '400'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const MachineTimelineList = ({ data, selectedMachines }) => {

    const classes = useStyles();

    return (
        <MachineTimeline machineNames={selectedMachines} displayRowLabels={true} />
    );
}
export default MachineTimelineList