import React, { useState, useEffect } from 'react';
import * as moment from 'moment';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import MachineDetails from './MachineDetails';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './Layout';
import UtilThermometer from './UtilThermometer';


const Production = ({ data, selectedMachines, width = "100%", height = 500, showXTitle = true, showDots = true, showTopBottom = false, showUtilization = false }) => {
    const [reacthistoricalAvgUtilization, setHistAvgUtil] = useState(0);
    const [currUtil, setCurrUtil] = useState(0);
    const [shiftStartTime, setShiftStartTime] = useState(null);
    const [capacityData, setCapacityData] = useState();
    const [sTopMachines, setTopMachines] = useState([]);
    const [sBottomMachines, setBottomMachines] = useState([]);
    const classes = useStyles();

    useEffect(() => {

        if (data?.customer ) {
           // console.log("Production userEffect");
            setTopBottomMachines(data);
            parseTitleComponents(data.title);
            initHistoricalAvgUtilization(data);

        }

    }, [data, selectedMachines]);


    useEffect(() => {
        if (shiftStartTime) {
            initCapacityChronoChart(data);
        }

    }, [shiftStartTime])

    const initHistoricalAvgUtilization = (sitedata) => {
        //figure out the count for each segment of the pie
        var selectedMachinesCount = 0;
        var historicalAvgUtilization = 0;
        var avgUtilization = 0;
        var avgCount = 0;
        //console.log("selectedMachines count: " + selectedMachines.length);
        for (var i = 0; i < sitedata.machines.length; i++) {
            if (selectedMachines.indexOf(sitedata.machines[i].machinename) !== -1)//TODO this.selectedmachineservice.ismachineselected(this.sitedata.machines[i].machinename))
            {
                
                //we want to include 0 into this calculation for how we are using it
                //to set an average line
                if (sitedata.machines[i].averageuptimepercent >= 0) {
                    historicalAvgUtilization += sitedata.machines[i].averageuptimepercent;
                    selectedMachinesCount++;
                }
                if (sitedata.machines[i].currentuptimepercent > 5) {
                    avgUtilization += sitedata.machines[i].currentuptimepercent;
                    avgCount++;
                    // console.log("MUTDashboardComponent.initUtilizationChart() avgUtilization: " + avgUtilization + " avgCount: " + avgCount);
                }

            }
        }
        if (avgUtilization > 0) {
            avgUtilization /= avgCount;
            avgUtilization = Math.trunc(avgUtilization);
        }
        //calculate the average utilization for the shiftcapacity
        historicalAvgUtilization /= selectedMachinesCount;
        // console.log("Production.initHistoricalAvgUtilization historicalAvgUtilization: " + historicalAvgUtilization);
       //  console.log("Production.initHistoricalAvgUtilization() current avgUtilization: " + avgUtilization);
        setHistAvgUtil(historicalAvgUtilization);
        setCurrUtil(avgUtilization);

    }
    //9/21/2019 this is a new way of displaying capacity or hours of spindle time.
    //using a avg trend line we can view current capacity in relation to the average
    //through out the shift period.  But this took a modification to the json data
    //being sent that contains an array of uptime for each machine in 15min periods.
    //If this array does not exist then we need to display the legacy capacity display.
    const initCapacityChronoChart = (sitedata) => {
        //---start new chrono feature
        //define the data structure
        //todo
        var selectedMachinesCount = selectedMachines.length;

        var capacitydata = [
            // ['hour', 'avg. ', 'current'],
            { hour: shiftStartTime.format('hh:mma'), avg: 0, current: 0 },
        ];
        //we will aggregate the total uptime for all the selected machines into 
        //an array of 15min periods
        var uptimeChrono = initCapacityChartWithChronoData(sitedata);
        var i = 0;
        //caclulate how many 15min periods we have in the shift.  This is assuming a whole number
        var equalpartsOfAverageUptime = sitedata.shifttotaltime / 15;
        //figure out the aggregate avg capacity (up time) 
        var totalHistoricalUptime = ((reacthistoricalAvgUtilization / 100) * sitedata.shifttotaltime) * selectedMachinesCount;
        //divide it up into the number of 15min periods we have in a shift
        var historicaluptimerate = (totalHistoricalUptime / equalpartsOfAverageUptime);
        // console.log("this.historicalAvgUtilization: " + reacthistoricalAvgUtilization + " this.selectedMachines: " + selectedMachinesCount + " totalHistoricalUptime: " + totalHistoricalUptime + " historicaluptimerate: " + historicaluptimerate);// + " this.sitedata.shiftelapsemins: " + this.sitedata.shiftelapsemins);
        var historicaltotaluptime = 0 //maintain the total avg aggregate, this will be a smooth line of constant slope
        var j = 0;
        var timeline = moment(shiftStartTime); //TODO the start time we display on the x-axis
        for (i = 1; i <= equalpartsOfAverageUptime; i++) {
            historicaltotaluptime = historicaluptimerate * i;
            timeline.add(15, 'minutes'); //add 15mins on each iterations
            //if we have live up time data for this period then display it as a red line
            var avgTrunc = Math.trunc((historicaltotaluptime / 60) * 10) / 10; //this will truncate to the tenths place
            var chronoTrunc = Math.trunc((uptimeChrono[j] / 60) * 10) / 10;
            if (j < uptimeChrono.length) {
                capacitydata.push({ hour: timeline.format('hh:mma'), avg: avgTrunc, current: chronoTrunc });
                j++;
            }
            else {
                //otherwise continue building the avg trend line
                capacitydata.push({ hour: timeline.format('hh:mma'), avg: avgTrunc, current: null });
            }
            //console.log("Chrono time: " + timeline.format('hh:mma'));
        }

        setCapacityData(capacitydata);
    }
    //This uses the chrono data from each machine and aggrates into a single array for 
    //the machines that are selected in the machine selector.
    const initCapacityChartWithChronoData = (sitedata) => {
        var uptimeTotal = [];

        //for each machine we need to grab the chrono array and aggregate the values
        //among the machines
        var maxChronoSize = 0;
        //futureChrono is used to add chrono timings to machines that lag in getting their data
        //to the server when the report was generated.
        //Then at the end we'll slice the array to the largest chrono array provided by any machine
        try {
            var futureChrono = 10;
            sitedata.machines.forEach(element => {
                var total = 0;
                //if the machine is selected then we want to includes it's uptime
                if (selectedMachines.indexOf(element.machinename) !== -1)//TODO (this.selectedmachineservice.ismachineselected(element.machinename)) 
                {
                    if (element.uptimechrono.length > maxChronoSize) {
                        //store the max size so we now how to slice the final result.
                        maxChronoSize = element.uptimechrono.length;
                    }
                    //for each element in the chrono array of the machine we want to 
                    //add/sum w/ the aggregate uptimeTotal array at the same index

                    var uptime = 0;
                    for (var i = 0; i < element.uptimechrono.length + futureChrono; i++) {
                        if (i < element.uptimechrono.length) {
                            uptime = element.uptimechrono[i];
                            total += uptime;
                        }

                        //  console.log("chrono adding uptime: " + uptime + " total: " + total);
                        if (uptimeTotal.length < i + 1) {

                            uptimeTotal.push(total);
                        }
                        else {
                            uptimeTotal[i] += total;
                        }
                    }
                }
            });
        } catch (exception) {
            console.log("initCapacityChartWithChronoData exception: " + exception);
        }

        // console.log("uptimeTotal.length: " + uptimeTotal.length + " maxChronoSize: " + maxChronoSize);
        //slice the array to the max chrono array size we received from the machines selected
        return uptimeTotal.slice(0, maxChronoSize);
    }

    //This should be considered a temporary solution until we update the
    //json coming from the server to contain the compoents of the title
    //i.e. shift, date, time.
    const parseTitleComponents = (title) => {
        //The title is in the form of: 
        //Shift 2: 10/17/2017 6:00:02 PM--6:56 PM
        //we want to check the shift checkbox 
        var index = title.indexOf(":");
        var shift = title.substr(0, index);
        //remove space in middle
        shift = shift.replace(/\s+/g, '');
        shift = shift.toLowerCase();
        //TODOD
        //this.setArchiveSelectedShift(shift);
        var date = title.substr(index + 1);
        //remove leading space
        date = date.trim();
        //find the index of the space after the date
        index = date.indexOf(' ');
        date = date.substr(0, index);

        // var shortDayName = getDayName(moment(date, 'MM/DD/YYYY').day(), true);
        //TODO
        //setArchivedatelabel(shortDayName + ' ' + date);
        // var dayName = getDayName(moment(date, 'MM/DD/YYYY').day());

        //we have to provide a string format that contains 2-digit values i.e. 09/03/2017
        var dateStr = moment(date, 'MM/DD/YYYY').format('MM/DD/YYYY');
        // console.log("Production.parseTitleComponents()  dateStr: " + dateStr);
        //find the index of the first /
        index = date.indexOf('/');
        var month = date.substr(0, index);
        //find index of last /
        var day = date.replace(month, "");
        index = day.lastIndexOf('/');
        day = day.substr(1, index - 1);//-1 so as not to include the /
        index = date.lastIndexOf('/');
        var year = date.substr(index + 1)//+1 so as not to include the /
        //find YYYY index
        index = title.indexOf(year);
        var time = title.substr(index + 4).trim();
        //setPiecharttitle(time);//= this.machinestatusoptions.title = time;
        var startTime = time.split('-')[0];
        //this.shiftStartTime = moment.utc(startTime, 'h:mm a');
        // console.log("parseTitleComponents startTime: " + startTime);
        setShiftStartTime(moment.utc(startTime, 'h:mm a'))

        //before we set the archive path we need to replace / with -
        date = dateStr.replace('/', '-');
        //need to do it twice b/c there are two and string.replace only replaces 1st occurance
        date = date.replace('/', '-');


    }
    // const getDayName = (dateNumber, shortName = false) => {

    //     var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    //     if (shortName) {
    //         days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    //     }
    //     var dayName = days[dateNumber];
    //     return dayName;
    // }

    const setTopBottomMachines = (sitedata) => {
        //copy the 
        if (showTopBottom) {
            var sortedData = JSON.parse(JSON.stringify(sitedata));
            var newCollection = [];
            sortedData.machines.map((machine)=>{
                if (selectedMachines.indexOf(machine.machinename) !== -1) 
                {
                    newCollection.push(machine);
                }

            });
            newCollection.sort(function (a, b) { return b.uptimeminutes - a.uptimeminutes });
            var topThree = newCollection.slice(0, 3);
            
            var bottomThree = newCollection.slice(-3);
            var top = topThree.map(a => a.machinename);
            var bottom = bottomThree.map(a => a.machinename);
            //if the selected machines is less than 6 then we want to diff. topThree from bottom
            var fBottom = bottom.filter(a=>!top.includes(a));
            setTopMachines(top);
            setBottomMachines(fBottom);
        }
        
    }
    //render
    return (
        <div>
            {/* using inline-flex makes responsiveContainer really slow */}
            <div style={{ display: 'flex'}}>
                
            <ResponsiveContainer  width={width} height={height} >
                <LineChart data={capacityData} >
                    <Line type="monotone" dataKey="avg" dot={showDots} stroke="#8884d8" />
                    <Line type="monotone" dataKey="current" dot={showDots} stroke="#880000" />
                    <CartesianGrid stroke="#ccc" />
                    <XAxis dataKey="hour" style={{ marginBottom: 10 }}>
                        {/* This label seems to get overlapped */}
                        {/* {(showXTitle)?<Label value="Shift Time" offset={-10}  position="insideBottom" />:<Label/>} */}

                    </XAxis>
                    <YAxis label={{ value: 'Spindle (hrs)', angle: -90, position: 'insideLeft', textAnchor: 'middle' }} />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36} />
                </LineChart>
                </ResponsiveContainer>
                
                    {showUtilization ? <div> <UtilThermometer theme={'light'} value={currUtil} max={100} steps={10} size={'large'} height={height - 80} avgutilization={reacthistoricalAvgUtilization} /></div>
                        : <div></div>}
                

            </div>
            <div>
                {showTopBottom ?
                    <div>
                        <div className={classes.appBarColors}>
                            <Typography variant="h6" align="center" className={classes.typography} style={{ marginLeft: 50 }}>Top 3 Machines
         </Typography>
                            <MachineDetails data={data} selectedMachines={sTopMachines} />
                        </div>
                        <div className={classes.appBarColors}>
                            <Typography variant="h6" align="center" className={classes.typography} style={{ marginLeft: 50 }}>Bottom 3 Machines</Typography>
                            <MachineDetails data={data} selectedMachines={sBottomMachines} />
                        </div>
                    </div>
                    : <div></div>}
            </div>

        </div>


    );
}

export default Production