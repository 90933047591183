import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import Layout from './Layout';
import messages from './messages';
import './styles/App.scss';
import MyContextProvider from './MyContext';
import { useJwt } from "react-jwt";



function App() {
  const [locale, setLocale] = useState('en');
  const [idToken, setIdToken] = useState(window.sessionStorage.getItem('id_token'));
  const { decodedToken, isExpired } = useJwt(idToken);
  const appClientId = "75l2ggv676fd7gds358b7fu32e";


  useEffect(() => {
   
    if (window.location.href.indexOf("Technymon") != -1) {
      console.log("window.location.href: " + window.location.href);
      var code = window.sessionStorage.getItem('code');

      // var cognitoRedirectURI = "http://localhost:3000/index.html";
      //if code == null then send them back to the login screen
      if (!code) {
        console.log("no code present redirecting to login");
        window.location.href = '/';
      }
      //console.log("idToken: " + idToken);
      if (idToken && decodedToken) {
        // console.log("decodedToken: " + decodedToken['cognito:username']);
         //console.log("isExpired: " + isExpired);
        if (isExpired) {

          console.log("token is expired redirecting to login");
          window.location.href = '/';
        }
        if (decodedToken['aud'] !== appClientId) {
          console.log("Token was not issued for this audience");
          console.log("redirecting to login");
          window.location.href = '/';
        }
      }
    }

  }, [idToken, decodedToken])

  // useEffect(async () => {
  //   //TODO: only applicable for technymon
  //   var code = window.sessionStorage.getItem('code');

  //  // var cognitoRedirectURI = "http://localhost:3000/index.html";
  //   //if code == null then send them back to the login screen
  //   if (!code)
  //   {
  //     console.log("redirecting to login");
  //     window.location.href='/';
  //   }


  // }, []);

  return (
    <MyContextProvider>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <Layout setLocale={setLocale} ></Layout>
      </IntlProvider>
    </MyContextProvider>
  );
}



export default App;