import * as myGlobals from './globals';
import * as moment from 'moment';
import getDate from 'date-fns/getDate'
import getDay from 'date-fns/getDay'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'

export async function getCustomerData(archiveDate, setData, setCustomerName, customerName, setArchiveDate=null) {
    var url = myGlobals.awscustomerdatagatewayurl;
    if (customerName) {
        if (archiveDate) {
            url += customerName + "/Archive/" + archiveDate + "/" + customerName + ".json";
        }
        else {
            url += customerName + "/" + customerName + ".json";
        }
       // console.log("MyContext.getCustomerData() url: " + url);
        fetch(url).then((response) => {
            response.json().then((jsonData) => {
                setData(jsonData.data);
                setCustomerName(jsonData.data.customer);
            }).catch(async () => {
                //reset to current shift most likely b/c an invalid date was selected
              //  console.log("MyContext.getCustomerData() reseting to current shift b/c of json parsing errors");
                const newResponse = await fetch(myGlobals.awscustomerdatagatewayurl + customerName + "/" + customerName + ".json");
                const newJsonData = await newResponse.json();
                setData(newJsonData.data);
                setCustomerName(newJsonData.data.customer);
                //since we reset to the current shift then we need to set the archive to null
                if (setArchiveDate)
                {
                   // console.log("MyContext.getCustomerData() reseting setArchiveDate to null");
                    setArchiveDate(null);
                }
            });
        }).catch(() => {
            console.log("MyContext.getCustomerData() fetch exception, maybe nextwork error");
        });
    }
}

//fetches the dialog reason codes the user can choose to select when 
//labeling down time
export const getDowntimeReasonsForDialog = async(customerName,setDialogReasonCodes)=>{
    fetchAndSetData(myGlobals.awscustomerdatagatewayurl + customerName + '/dialogdowntimereasoncodes', setDialogReasonCodes, "data");
   
}
export const getUptimeData = async(customerName,setUptimeData)=>{
    fetchAndSetData(myGlobals.awscustomerdatagatewayurl + customerName + '/uptime', setUptimeData, "data");
}

export const getDisplayConfigData = async(customerName, setDisplayConfigData)=>{
    fetchAndSetData(myGlobals.awscustomerdatagatewayurl + customerName + '/displayconfig', setDisplayConfigData, "data");
}


///production/customers/Divfit?dataname=downtimereasoncodes_10-16-2020_Friday_shift1
export const getPersistedDowntimeReasonCodes = async(customerName, archiveString, setPersistedDowntimeReasonCodes)=>{
    fetchAndSetData(myGlobals.awscustomerdatagatewayurl + "customers/" + customerName + "?dataname=downtimereasoncodes_" + archiveString, setPersistedDowntimeReasonCodes, "Item");
}

export const getDowntimeReport = async(customerName, setDowntimeReport)=>{
    fetchAndSetData(myGlobals.awscustomerdatagatewayurl + customerName + '/downtimereport', setDowntimeReport, "downtime");
}

const fetchAndSetData=async(url, setter, firstElement=null )=>{
   // console.log("ContextDatafetcher.fetchAndSetData url: " + url);
    const response = await fetch (url)
    const jsonData = await response.json();
    if (firstElement)
    {
        setter(jsonData[firstElement]);
    }
    else
    {
   // console.log("Datafetcher getDowntimeReasonsForDialog : " + jsonData.data.length);
        setter(jsonData);
    }
}

export const parseShiftTitle = (title) => {
    if (title){
       var dayMap = [
           "Sunday",
           "Monday",
           "Tuesday",
           "Wednesday",
           "Thursday",
           "Friday",
           "Saturday"
       ];
       //The title is in the form of: 
       //Shift 2: 10/17/2017 6:00:02 PM--6:56 PM
       //we want to check the shift checkbox 
       var index = title.indexOf(":");
       var shift = title.substr(0, index);
       //remove space in middle
       shift = shift.replace(/\s+/g, '');
       shift = shift;//.toUpperCase();
    //   setShiftName(shift);
       //this.setArchiveSelectedShift(shift);
       var date = title.substr(index + 1);
       //remove leading space
       date = date.trim();
       //find the index of the space after the date
       index = date.indexOf(' ');
       date = date.substr(0, index);
    //   setShiftDate(date);
       var shortDayName = dayMap[moment(date, 'MM/DD/YYYY').day()];
     // setShiftDay(shortDayName);
       index = title.indexOf(date);
       var time = title.substr(index + date.length).trim();
       // this.archivedatelabel = shortDayName + ' ' + date;
       // var dayName = this.getDayName(moment(date, 'MM/DD/YYYY').day());
   // setShiftTime(time);
       return { shiftname:shift, shiftdate:date, shiftday:shortDayName, shifttime:time};

   }

}
export const getArchiveStringFromDateShift = (aDate, shiftName) => {
    if (aDate && shiftName) {
        var dayMap = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
        ];
//        console.log("MyContext sArchiveDate changed: " + aDate);
        var date = getDate(aDate);
        var day = getDay(aDate);
        var year = getYear(aDate);
        var month = getMonth(aDate) + 1;//need to add 1 b/c January =0
        //console.log("month: " + month + " date: " + date + " year: " + year + " day: " + dayMap[day]);

        //need to enforce 2 character date string
        var strDate = ("0" + date).slice(-2);
        var strMonth = ("0" + month).slice(-2);
        var archiveString = strMonth + "-" + strDate + "-" + year + "_" + dayMap[day] + "_" + shiftName.toLowerCase();
       // console.log("contextDataFetcher.getArchiveStringFromDateShift archiveString: " + archiveString );
        return archiveString;
    }
}
//returns the current archive string from the data being viewed
export const getCurrentDateShiftString = (sitedata)=>{
    
    var currentDashboardView = sitedata;//['data'];
    //string is in the format: Shift 1: 1/24/2019 6:00:01 AM--2:25 PM
    //need it as: 01-24-2019_Thursday_shift1 //note 2 digit Month and Date and lowercase shift
    var title = currentDashboardView['title'];
    var result = parseShiftTitle(title);
    var date = moment(result.shiftdate,'MM/DD/YYYY');
    var DateShiftBeingViewed =  date.format('MM-DD-YYYY') + '_' + result.shiftday + '_' + result.shiftname.toLowerCase();//currentDashboardView['title'];
   // console.log("ContextDataFetcher.getCurrentDateShiftString() " + DateShiftBeingViewed)// + ' datestr: ' + datestr + ' dayName: ' + dayName + ' shiftstr: ' + shiftstr);
    //only ever set 1 time
    return DateShiftBeingViewed;
 

  }