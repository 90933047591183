import React, { useContext, useEffect } from 'react';
import { MyContext } from './MyContext';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useStyles } from './Layout';
import { HiRefresh } from "react-icons/hi";
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

const Refresh = () => {
    const { setLastUpdateTime, autoRefresh } = useContext(MyContext);
    const classes = useStyles();

    useEffect(() => {
        //supporting auto refresh of the dashboard
        console.log("Refresh autoRefresh: " + autoRefresh);
        if (autoRefresh) {
            var atimerId = setInterval(() => {
                setLastUpdateTime(new Date());
            }, 1000*60*5);  //update every five minutes
            console.log("Refresh.useEffect CreateTimer id: " + atimerId);
            return () => clearInterval(atimerId);
        }
    }, [autoRefresh])

    const handleClick = () => {
        setLastUpdateTime(new Date());
    };

    return (

        <Typography component="span" variant="inherit" className={classes.title}>
            <Tooltip TransitionComponent={Zoom} title="Click to refresh and return to the current shift">
                <Button color="inherit" style={{ maxWidth: '33px', maxHeight: '30px', minWidth: '35px', minHeight: '30px' }} onClick={handleClick}>
                    <HiRefresh />
                </Button>
            </Tooltip>
        </Typography>

    );
}
export default Refresh;

