import { useEffect, useState, useContext } from 'react';
import * as dataFetcher from './ContextDataFetcher';
import { MyContext } from './MyContext';

const useDisplayConfig = ({displayName}) => {
    const { customerName, selectedMachines, setSelectedMachines, data } = useContext(MyContext);
    const [configDate, setConfigData] = useState();
    //need to 
    useEffect(() => {

        if (displayName && customerName && !configDate) {
            dataFetcher.getDisplayConfigData(customerName, setConfigData);
            //should need to only set the config selected machines one time
            //fetch the configuration for this configId
            
        }
    }, [displayName, customerName]);
    useEffect(() => {

        //it is assumed that the selectedMachines collection in context has already been 
        //set to show/hide customer specific machines.  This is just a refining of that collection
        if (configDate && selectedMachines.length>0) {
            //if there is not one then do nothing
            //else modify the new seletedMachines
            configDate.forEach(element => {
                console.log("element.displayname: " + element.displayname);
                if (element.displayname == displayName) {
                    //we found our display config that is being requested

                    var result = element.machinenames;
                    var subset = selectedMachines.filter(a=>result.indexOf(a)!=-1)
                    //results should be an array of machines names
                    setSelectedMachines(subset);
                    console.log("useDisplayConfig.useEffect() display config data loaded: " + subset.length);
                    return;
                }
            });
        }
    }, [configDate, data]);
}
export default useDisplayConfig;