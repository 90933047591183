import React, { useState, useContext, useEffect } from 'react';
import Aside from './Aside';
import Main from './Main';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {
    ProductionComponent, ProductionPerformanceComponent, UtilGraphComponent,
    MachineDetailsComponent, UtilGaugeComponent, MachineInfoComponent,
    OperatorViewComponent, UptimeAnalyticsByShiftComponent,
    UptimeAnalyticsTotalComponent, UptimeAnalyticsDailyComponent, UtilizationAnalyticsByShiftComponent,
    AnalyticsTopMachineComponent, DowntimeTotalComponent, ShopFloorDisplayComponent, AnalyticsDowntimeReportComponent
} from './Dashboard.js';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import InfoIcon from '@material-ui/icons/Info';

import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

import ShowTargets from './ShowTargets'
import MachineSelectDrawer from './MachineSelectDrawer';
import ShiftTitle from './ShiftTitle';
import { MyContext } from './MyContext';


export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    appBarColors: {
        background: theme.palette.primary.main,
    },
    typography:
    {
        ...theme.typography.h6,
        color: theme.palette.background.paper,
        padding: theme.spacing(1),
    }
}));
function Layout({ setLocale }) {
    const classes = useStyles();

    const { data, currentView, operatorView, currentViewTooltip, setAutoRefresh } = useContext(MyContext)
    const [rtl, setRtl] = useState(false);
    const [collapsed, setCollapsed] = useState(true);
    const [image, setImage] = useState(true);
    const [toggled, setToggled] = useState(false);

    const handleCollapsedChange = (checked) => {
        setCollapsed(checked);
    };

    const handleRtlChange = (checked) => {
        setRtl(checked);
        setLocale(checked ? 'ar' : 'en');
    };
    const handleImageChange = (checked) => {
        setImage(checked);
    };

    const handleToggleSidebar = (value) => {
        setToggled(value);
    };
    useEffect(() => {
        //need to update the title
    }, [data]);

    return (
        <div className={`app ${rtl ? 'rtl' : ''} ${toggled ? 'toggled' : ''}`}>
            <Router>

                {!operatorView && <Aside
                    image={image}
                    collapsed={collapsed}
                    rtl={rtl}
                    toggled={toggled}
                    handleToggleSidebar={handleToggleSidebar}
                    handleCollapsedChange={handleCollapsedChange}
                />}
                <div className={classes.root}>
                    {!operatorView && <AppBar position="static">
                        <Toolbar>
                            <Tooltip TransitionComponent={Zoom} title={currentViewTooltip}>
                                <InfoIcon fontSize='small' />
                            </Tooltip>
                            <Typography variant="h5" align="left" className={classes.title}>
                                {currentView}
                            </Typography>


                            <Typography variant="h5" align="left" className={classes.title}
                                style={{ marginRight: '200px' }}>
                                <ShiftTitle />
                            </Typography>
                            <div>
                                <MachineSelectDrawer />
                                <ShowTargets />
                            </div>
                        </Toolbar>
                    </AppBar>
                    }
                    <Switch>

                        <Route exact path="/start/:id" >
                            <ProductionComponent></ProductionComponent>
                        </Route>
                        <Route exact path="/start/:id/refresh" render={() => {
                            setAutoRefresh(true);
                            return <ProductionComponent />
                        }
                        }>
                        </Route>
                        <Route path="/start/:id/Archive/:archivedateshift" >
                            <ProductionComponent></ProductionComponent>
                        </Route>
                        <Route path="/start/:id/utilgauge" >
                            <UtilGaugeComponent ></UtilGaugeComponent>
                        </Route>
                        <Route path="/start/:id/prodperf" >
                            <ProductionPerformanceComponent></ProductionPerformanceComponent>
                        </Route>
                        <Route exact path="/start/:id/utilgraph" >
                            {/* <UtilizationGraph sitedata={data}></UtilizationGraph> */}
                            <UtilGraphComponent></UtilGraphComponent>
                        </Route>
                        <Route path="/start/:id/machinedetails" >
                            <MachineDetailsComponent></MachineDetailsComponent>
                        </Route>
                        <Route path="/start/:id/machineinfo/:machinename" >
                            <MachineInfoComponent isOperatorView={false}></MachineInfoComponent>
                        </Route>

                        <Route path="/start/:id/analyticsbyshift" >
                            <UptimeAnalyticsByShiftComponent />
                        </Route>
                        <Route path="/start/:id/analyticstotal" >
                            <UptimeAnalyticsTotalComponent />
                        </Route>
                        <Route path="/start/:id/analyticsdaily" >
                            <UptimeAnalyticsDailyComponent />
                        </Route>
                        <Route path="/start/:id/analyticsutilbyshift" >
                            <UtilizationAnalyticsByShiftComponent />
                        </Route>
                        <Route path="/start/:id/analyticstopmachine" >
                            <AnalyticsTopMachineComponent />
                        </Route>
                        <Route path="/start/:id/analyticsdowntime" >
                            <AnalyticsDowntimeReportComponent />
                        </Route>
                        <Route path="/start/:id/downtimetotals" >
                            <DowntimeTotalComponent />
                        </Route>
                        <Route path="/start/:id/:machinename" >
                            <OperatorViewComponent isOperatorView={true}></OperatorViewComponent>
                        </Route>

                        <Route path="/start/shopfloordisplay/:id/:displayname" >
                            <ShopFloorDisplayComponent />
                        </Route>


                        {/* 
                        <Route path="/"
                            exact
                            render={(props) => (
                                <Main
                                    image={image}
                                    toggled={toggled}
                                    collapsed={collapsed}
                                    rtl={rtl}
                                    handleToggleSidebar={handleToggleSidebar}
                                    handleCollapsedChange={handleCollapsedChange}
                                    handleRtlChange={handleRtlChange}
                                    handleImageChange={handleImageChange}
                                />
                            )} /> */}
                    </Switch>
                </div>
            </Router>
        </div>

    );
}
export default Layout;


