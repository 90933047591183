import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from './MyContext';
import UtilizationGraph from './UtilizationGraph';
import * as showTargets from './ShowTargets';
import useInterval from './useInterval';
import useDisplayConfig from './useDisplayConfig';
import { makeStyles } from '@material-ui/core/styles';
import { colorBlue, colorGreen, colorYellow, colorRed } from './UtilizationGraph';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    square: {
        width: '25px',
        height: '25px',
        marginLeft: '10px',
        marginRight: '10px',
        opacity: '.9',
        display: 'inlineBlock',
        marginTop: '5px'
    },
    utilizationkeyrow: {
        margin: 'auto',
        width: '50%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '50px'
    },
    center: {
        font: 'bold 12px/40px Georgia, serif',
        marginBottom: '10px',
        //   verticalAlign: 'top',
        textAlign: 'right',
        clear: 'both',
        float: 'left',
        height: '15px',
    },
    footer:{
    font:'25px Georgia, serif',
    color: 'blue',
    marginTop: '10px',
    marginLeft:'60px',
}
});

const ShopFloorDisplay = ({displayConfigId=null}) => {
    const classes = useStyles();
    const { setLastUpdateTime, selectedMachines, setSelectedMachines, data } = useContext(MyContext);

    useInterval(() => {
        //console.log("OperatorView useEffect customerName: " + customerName);
        setLastUpdateTime(new Date());
    }, 60000 * 5);

    useEffect(() => {
        if (data) {
            // console.log("ShopFloorDisplay customerName useEffect data changed selectedMachines: " + selectedMachines.length);
            //a customer will set machine.customersethide to have it not display so pass true for evalSetHide
            showTargets.handleSetSelectedMachinesForShopFloorDisplay(data, selectedMachines, setSelectedMachines)

        }
    }, [data]);
    useEffect(() => {
        // console.log("ShopFloorDisplay useEffect selectedMachines: " + selectedMachines.length);
    }, [selectedMachines]);

    useDisplayConfig({displayName:displayConfigId});

    return (
        <div>
            <UtilizationGraph data={data} selectedMachines={selectedMachines} isShopFloorDisplay={true} />
            <div style={{ display: 'block' }}>
                <div className={classes.utilizationkeyrow}>
                <div style={{display:'flex',  alignItems:'center'}}>
                        <div className={classes.square} style={{ background: colorBlue }}></div><label  className={classes.center}>Idle</label>
                        </div>
                        <div style={{display:'flex',  alignItems:'center'}}>
                        <div className={classes.square} style={{ background: colorRed }}></div><label  className={classes.center}>Under Perform</label>
                        </div>
                        <div style={{display:'flex',  alignItems:'center'}}>
                        <div className={classes.square} style={{ background: colorYellow }}></div><label  className={classes.center}>Performing</label>
                        </div>
                        <div style={{display:'flex',  alignItems:'center'}}>
                        <div className={classes.square} style={{ background: colorGreen}}></div><label  className={classes.center}>Exceeding</label>
                        </div>

                </div>
                <div className={classes.utilizationkeyrow}><h2 className={classes.footer}>www.machinetracking.com</h2></div>
            </div>
        </div>
    );
};
export default ShopFloorDisplay;