import React, { useState, useEffect } from 'react';

import * as datafetcher from './ContextDataFetcher'
export const MyContext = React.createContext("not set");


const MyContextProvider = ({ children }) => {
    const [selectedMachines, setSelectedMachines] = React.useState([]);
    const [sArchiveDate, setArchiveDate] = useState(null);
    const [currentView, setCurrentView] = useState("");
    const [currentViewTooltip, setCurrentViewTooltip] = useState("");

    const [data, setData] = useState(null);
    const [customerName, setCustomerName] = useState(null);
    const [dialogReasonCodes, setDialogReasonCodes] = useState(null);
    const [persistedDowntimeReasonCodes, setPersistedDowntimeReasonCodes] = useState(null);
    const [operatorView, setOperatorView] = useState(false);
    const [lastUpdateTime, setLastUpdateTime] = useState(new Date());
    const [uptimeData, setUptimeData] = useState(null);
    const [analyticsDailyDataShort, setAnalyticsDailyDataShort] = useState(null);
    const [analyticsDailyDataLong, setAnalyticsDailyDataLong] = useState(null);
    const [dowtimeTotals, setDowntimeTotals] = useState();
    const [downtimeReport, setDowntimeReport] = useState();
    const [autoRefresh, setAutoRefresh] = useState(false);
    
    useEffect(() => {
        //console.log("Mycontext useEffect customerName: " + customerName + " lastUpdateTime changed to: " + lastUpdateTime + " sArchiveDate: " + sArchiveDate);
        if (customerName) {

            //if the archive is set then this means we have an url containing an archive i.e. from an email report.
            if (!sArchiveDate)
            {
                datafetcher.getCustomerData(null, setData, setCustomerName, customerName);
            }
            //only need to get dialog reason codes once per customer
            datafetcher.getDowntimeReasonsForDialog(customerName, setDialogReasonCodes);
            //only need to get historical uptime data once per customer
            datafetcher.getUptimeData(customerName, setUptimeData);
        }
    }, [customerName])

    useEffect(() => {
        // transformDate(sArchiveDate?.date, sArchiveDate?.shiftName);
        var archiveString = datafetcher.getArchiveStringFromDateShift(sArchiveDate?.date, sArchiveDate?.shiftName)
        if (archiveString) {
            //console.log("MyContext.useEffect new archiveString: " + archiveString);
            //TODO compare the archiveString to what we currently have loaded
            //and don't call getCustomerData if they are the same.
            if (data) {
                var currString = datafetcher.getCurrentDateShiftString(data);
                console.log("MyContext.useEffect currString: " + currString);
                if (archiveString !== currString) {
                    console.log("MyContext.useEffect new archiveString diff than currString so fetching archive data");
                    datafetcher.getCustomerData(archiveString, setData, setCustomerName, customerName, setArchiveDate);
                }
            } else {
                datafetcher.getCustomerData(archiveString, setData, setCustomerName, customerName, setArchiveDate);
            }
        }
    }, [sArchiveDate])

    
    useEffect(() => {

        //console.log("Mycontext useEffect customerName: " + customerName + " lastUpdateTime changed to: " + lastUpdateTime);
        if (customerName) 
        {
            datafetcher.getCustomerData(null, setData, setCustomerName, customerName);
            // datafetcher.getDowntimeReasonsForDialog(customerName, setDialogReasonCodes);//, setArchiveDate);
            setArchiveDate(null);

        }
    }, [lastUpdateTime])

    useEffect(() => {

        if (data) {
            var currArchiveString = datafetcher.getCurrentDateShiftString(data);
            datafetcher.getPersistedDowntimeReasonCodes(customerName, currArchiveString, setPersistedDowntimeReasonCodes);
        }
    }, [data])



    return (

        <MyContext.Provider value={{
            customerName,setCustomerName,
            data,
            selectedMachines, setSelectedMachines,
            sArchiveDate, setArchiveDate,
            currentView, setCurrentView,
            dialogReasonCodes,
            persistedDowntimeReasonCodes, setPersistedDowntimeReasonCodes,
            operatorView, setOperatorView,
            lastUpdateTime, setLastUpdateTime,
            uptimeData,
            analyticsDailyDataShort, setAnalyticsDailyDataShort,
            analyticsDailyDataLong, setAnalyticsDailyDataLong,
            dowtimeTotals, setDowntimeTotals,
            currentViewTooltip, setCurrentViewTooltip,
            downtimeReport, setDowntimeReport,
            autoRefresh, setAutoRefresh
        }}>
            {children}
        </MyContext.Provider>
    );
};

export default MyContextProvider;