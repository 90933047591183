import 'date-fns';
import React, { useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import * as moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import addDays from 'date-fns/addDays'
import { MyContext } from './MyContext';
import * as datafetcher from './ContextDataFetcher'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';


const useStyles = makeStyles((theme) => ({
  root: {
    width: 0,
    marginRight: 20,

    //TODO make Icon white
  },
}));




//todo set minDate maxDate on control
export default function ArchiveDateSelection() {
  // The first commit of Material-UI
  const { sArchiveDate,setArchiveDate,  lastUpdateTime, data } = useContext(MyContext)
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [shiftName, setShiftName] = React.useState("shift1");
  const classes = useStyles();
  const today = new Date();
  const minDate = addDays(today, -90);
 

  useEffect(() => {
    //we don't want to do this if we have not mounted yet otherwise it causes
    //and archive date to be set for the initial loading which is wrong b/c the 
    //the init loading is the current shift.
      if (data && sArchiveDate) {
        var result = datafetcher.parseShiftTitle(data?.title);
        if (result)
        {
        setShiftName(result.shiftname);
        var date = moment(result.shiftdate,'MM/DD/YYYY');
        setSelectedDate(date.toDate());
        
        
        console.log("ArchiveDataSelection sArchiveDate changed: " + result.shiftdate + " shiftName: " + result.shiftname);
        }
     
    }
  }, [sArchiveDate, data])
  
  useEffect(() => {
    //lastUpdateTime gets updated to the current dateTime
    //by the operator view and by the refresh button.
    //when this happens we want to make sure we reset the selectedDate
    //in this component to the current dataTime as well
    setSelectedDate(new Date());
   
}, [lastUpdateTime])


  const handleDateChange = (date) => {
    setSelectedDate(date);
    //update context
    setArchiveDate({ date: date, shiftName: shiftName });
  };

  const handleAddDay = () => () => {
    var newDate = addDays(selectedDate, 1);
    if (newDate < today) {//if newDate is not greater than today
      // console.log("handleAddDay newDate: " + newDate);
      setSelectedDate(newDate);
      setArchiveDate({ date: newDate, shiftName: shiftName });
    }

  };
  const handleSubtractDay = () => () => {
    console.log("handleSubtractDay selectedDate: " + selectedDate + " shiftName: " + shiftName);
    var newDate = addDays(selectedDate, -1);
    if (newDate > minDate) {//if newDate is greater than 90 days ago
      console.log("handleSubtractDay newDate: " + newDate + " shiftName: " + shiftName);
      setSelectedDate(newDate);
      setArchiveDate({ date: newDate, shiftName: shiftName });
    }

  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container wrap="nowrap" >
        <Grid item xs={10}>
        <Tooltip TransitionComponent={Zoom} title="Go back a day">
          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }} color="inherit" onClick={handleSubtractDay()}>
            <ArrowBackIosIcon />
          </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={10}>
          <Paper >
          <Tooltip TransitionComponent={Zoom} title="Use this caledar to view data from a specific day.">
            <KeyboardDatePicker
              style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
              showTodayButton={true}
              disableFuture={true}
              minDate={minDate}
              className={classes.root} //use this to hide the input field
              // margin="normal"
              id="date-picker-dialog"
              // label="Date picker dialog"
              format="MM/dd/yyyy"
              // value={selectedDate}
              onChange={handleDateChange}
              showTodayButton={true}
              InputAdornmentProps={{ position: "start" }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}

            />
            </Tooltip>
          </Paper>
        </Grid>
        <Grid item xs={10}>
        <Tooltip TransitionComponent={Zoom} title="Go forward a day, but not the future.">
          <Button style={{ maxWidth: '33px', maxHeight: '30px', minWidth: '35px', minHeight: '30px' }} color="inherit" onClick={handleAddDay()}>
            <ArrowForwardIosIcon />
          </Button>
</Tooltip>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>

  );
}
