import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MachineInfo from './MachineInfo';

//for machine
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const MachineDetails = ({ data, selectedMachines }) => {

    const classes = useStyles();
   


    return (

        <div className={classes.root}>
            
            <Grid container direction="row"  justify="start"  alignItems="center">

                {data?.machines.map((aMachine) => (
                     (selectedMachines.indexOf(aMachine.machinename) != -1) ?
                    <Grid item xs key={aMachine.machinename}>
                        <MachineInfo machineinfo={aMachine} />
                    </Grid> : <div ></div>
                ))}



            </Grid>
        </div>
    );
}
export default MachineDetails