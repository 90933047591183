import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from './MyContext';
import ArchiveDateSelection from './ArchiveDateSelection';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as moment from 'moment';
import { useStyles } from './Layout';
import Radio from '@material-ui/core/Radio';
import Refresh from './Refresh';
import * as datafetcher from './ContextDataFetcher'

const ShiftTitle = () => {
    const { data, sArchiveDate, setArchiveDate } = useContext(MyContext);
    const classes = useStyles();
    const [shiftName, setShiftName] = useState(null);
    const [shiftDate, setShiftDate] = useState(null);
    const [shiftDay, setShiftDay] = useState("");
    const [shiftTime, setShiftTime] = useState("");



    useEffect(() => {
        var result = datafetcher.parseShiftTitle(data?.title);
        if (result) {
            setShiftName(result.shiftname);
            setShiftDate(result.shiftdate);
            setShiftDay(result.shiftday);
            setShiftTime(result.shifttime);
        }
    }, [data])
    const handleShiftChange = (event) => {

        console.log(" ShiftTitle handleShiftChange event: " + event.target.value + " sArchiveDate: " + sArchiveDate);
        setShiftName(event.target.value);
        //if (sArchiveDate)
        {
            console.log(" ShiftTitle handleShiftChange sArchiveDate: " + sArchiveDate?.date);
            setArchiveDate({ date: (sArchiveDate ? sArchiveDate.date : new Date), shiftName: event.target.value })
        }
    };

    return (
        <div>
            <Typography variant="inherit" align="center" className={classes.title}>
                <Typography variant="subtitle2" className={classes.title}>
                    {shiftDay} {shiftDate}<Refresh />
                </Typography>
                <Typography variant="subtitle2" align="center" className={classes.title}>

                    <Radio
                        value="Shift1"
                        checked={shiftName == 'Shift1'}
                        name="shiftRadio"
                        onChange={handleShiftChange} />
                    Shift 1
                    <Radio
                        value="Shift2"
                        checked={shiftName == 'Shift2'}
                        name="shiftRadio"
                        onChange={handleShiftChange} />
                        Shift 2
                    <Radio
                        value="Shift3"
                        checked={shiftName == 'Shift3'}
                        name="shiftRadio"
                        onChange={handleShiftChange} />
                        Shift 3
                </Typography>
                <Typography variant="subtitle1" align="center" className={classes.title} style={{ marginLeft: 50 }}>
                    {shiftTime}
                    <Button color="inherit" >
                        <ArchiveDateSelection color="inherit" />
                    </Button>
                </Typography>
            </Typography>
        </div>
    );
}
export default ShiftTitle;

