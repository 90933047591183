import { useState, useContext, useEffect } from 'react';
import { MyContext } from './MyContext';



const useUtilizationData = () => {
    const { uptimeData, selectedMachines } = useContext(MyContext)
    const [avgOneByDate, setAvgOneByDate] = useState([])
    const [avgTwoByDate, setAvgTwoByDate] = useState([])
    const [avgThreeByDate, setAvgThreeByDate] = useState(null)
    

    useEffect(() => {

        if (uptimeData) {
            //This will create 3 collections.  1 for each shift
            //and sum the daily uptime across machines.
            const sumOneDate = [];
            const sumTwoDate = [];
            const sumThreeDate = [];
            avgShiftByDate(sumOneDate, "s1percent");
            avgShiftByDate(sumTwoDate, "s2percent");
           // var hasThreeShifts = false;
            if (uptimeData[0]["s3percent"] != null) {
               // hasThreeShifts = true;
                avgShiftByDate(sumThreeDate, "s3percent");
                setAvgThreeByDate(sumThreeDate);
            }
            setAvgOneByDate(sumOneDate);
            setAvgTwoByDate(sumTwoDate);

        }

    }, [uptimeData, selectedMachines]);

    const avgShiftByDate = (avgDate, shiftProp) => {
       
        //get distinct dates
        const uniqueDates = [];
        for (let value of uptimeData) {

            //break this appart from "10/01/20" "month/year"
            var parts = value.date.split('/');
            var monthYear = parts[0] + "/" + parts[2];
            if (uniqueDates.indexOf(monthYear) === -1) {
                uniqueDates.push(monthYear);
            }

        }

        const sumReducer = (accumulator, currentValue) => accumulator + currentValue;
        uniqueDates.forEach((aDate) => {
            //to calculate the daily average at the same time
            //keep track of the daysAboveZero
            let filterByDate = uptimeData.filter(a => {
                //Needs to take into account the machine selection
                //The issue w/ this is machines that we have monitored in the past but no longer do
                //will not be included in these totals...ugh
                var result = false;
                if (selectedMachines.indexOf(a.machinename) !== -1 && a[shiftProp]>5) {//we only consider days above 5%
                    var parts = a.date.split('/');
                    var monthYear = parts[0] + "/" + parts[2];
                    result = monthYear === aDate
                    return result
                }
                return result;
            });
             let percent = filterByDate.map(a => a[shiftProp]);
            let sum = 0;
            if (percent.length>0)
            {
                sum = percent.reduce(sumReducer);
                 //we take the avg of these percents b/c a sum doesn't tell us anything
                sum/=percent.length;
            }
           
           
            //round to 0 decimal places
            sum = Math.round(sum);
            //console.log("sum percent for date: " + aDate + " " + sum);
            avgDate.push({ date: aDate, avg: sum });
            

        });
        
    };

    return {
        avgOneByDate: avgOneByDate,
        avgTwoByDate: avgTwoByDate,
        avgThreeByDate: avgThreeByDate
    };


};
export default useUtilizationData;