import * as myGlobals from './globals';
import * as datafetcher from './ContextDataFetcher'


export const getReasonText = (machineName, timeperiod, persisteddowntimereasons, dialogDowntimeReaons) => {
  var result = null;
  var reasontext = null;
  var reasonid=null;
  //TODO this seems really inefficient to create this collection for every downtime to see if there is a
  //reasoncode label
  if (persisteddowntimereasons) {
    var machineDownTimes = initDownTimeReaons(persisteddowntimereasons);
    var reasonCodeInt = getReasonCode(machineName, timeperiod, machineDownTimes);
    if (reasonCodeInt) {
      var foundReasonCode = dialogDowntimeReaons.find(reasonCode => reasonCode.id === reasonCodeInt.reasons[0]);//.name;
      if (foundReasonCode) {
        reasonid=foundReasonCode.id;
        result = foundReasonCode.name;
        reasontext = reasonCodeInt.text;
        
      }
      else {
        console.log("DownTimeDialogsService.getReasonText ex: no text for code: " + reasonCodeInt.reasons[0]);
      }
    }
  }
  return { reasoncode: result, reasontext: reasontext, reasonid: reasonid };
}



export const initDownTimeReaons = (downtimereasons) => {
  //clear the collection before we init
  var machineDownTimes = [];
  if (downtimereasons) {
    downtimereasons.data.forEach(downtimereason => {
      //object already contains a duration value, just need to convert it to secconds then minutes
      // var minutes = downtimereason.timeperiodend - downtimereason.timeperiodstart; //millisecond
      // minutes = minutes / 1000.0; //convert to seconds
      // minutes = minutes / 60.0; //convert to minutes
      //downtimereason.duration = minutes;
      var minutes = downtimereason.duration/ 1000.0;
      var minutes = minutes/ 60;
      setReason(downtimereason.machinename, downtimereason.timeperiodstart, downtimereason.reasoncodes, minutes, downtimereason.text, machineDownTimes);
    });
  }
  return machineDownTimes;
}

//reasontext is the free form text a user is allowed to add when labeling down time.
export const setReason = (machineName, timeperiodstart, reasoncodes, durationMins = 0, reasonText = '', machineDownTimes) => {
  // console.log("DownTimeDialogsService.setReason " + machineName + " " + timeperiodstart + " " + reasoncodes + ' reasoncode size ' + reasoncodes.length);
  var existingKey = getReasonCode(machineName, timeperiodstart, machineDownTimes);
  if (existingKey) {
    existingKey.reasons = reasoncodes;
    existingKey.text = reasonText;
  }
  else {
    //we key the data into the collection by machineName + timeperiod
    var collectionKey = machineName + timeperiodstart;
    //  console.log("DownTimeDialogsService.setReason adding key value, collectionKey: " + collectionKey + ' reasoncodes: ' + reasoncodes);

    machineDownTimes.push({ name: collectionKey, reasons: reasoncodes, duration: durationMins, text: reasonText });
  }
}

const getReasonCode = (machineName, timeperiod, machineDownTimes) => {
  //we key the data into the collection by machineName + timeperiod
  var collectionKey = machineName + timeperiod;
  //console.log("DownTimeDialogsService.getReasonCode(): " + collectionKey);
  var result = machineDownTimes.find(element => element.name === collectionKey);
  return result;
}


/**
  * to support editing archived data we need to modify the cgi script to take
  * a path
    * Must pass in the leading / if archiveDir is not empty string
    */
export const persistDownTimeReasonCodes = (downtime, customer, archiveDir, contextSetter) => {

  //var aDate = new Date(downtime.timeperiodstart);

  console.log("DownTimeDialogsService.persistDownTimeReasonCodes() aDate: " + downtime.debug + " archiveDir: " + archiveDir);
  //let headers = new Headers({ 'Content-Type': 'application/json' });
  //let options = new RequestOptions({ headers: headers });
  // const httpOptions = {
  //   headers: new HttpHeaders({
  //     'Content-Type': 'application/json'
  //   })
  // };
  // headers.append("Access-Control-Allow-Methods", "PATCH, POST, GET, PUT, DELETE, OPTIONS");
  let body = JSON.stringify(downtime);
  console.log("DownTimeDialogsService.persistDownTimeReasonCodes body " + body);
  //let url = this.SiteDataUrl + 'cgi-bin/downtimereporting/downtimereasoncode.py?' + customer + archiveDir

  let dataname = "downtimereasoncodes"
  //TODO handle setting archived downtime reason codes
  // if (archiveDir) {
  //   //since we are storing in dynamodb vs the file system we strip off the 'Archive'.
  //   //we can't refactor this to remove it throughout the site b/c it's used as in indicator
  //   //for other functionality.
  //   archiveDir = archiveDir.replace('/Archive/', '')
  // } else {
  //   //we are editing the current shift downtime data
  //   //we want to always pass in a the date and shift to edit the 
  //   archiveDir = this.timelineService.DateShiftBeingViewed;
  // }
  let SiteDataUrl = myGlobals.awscustomerdatagatewayurl;
  let lambdaDdbUrl = SiteDataUrl + 'customers/';
  dataname += '_' + archiveDir;
  let url = lambdaDdbUrl + customer + "?dataname=" + dataname;
  console.log(url);

  //http.post does not work w/ the server
  // var request = this.http.post(url, body, httpOptions)
  //   .pipe(
  //     catchError(this.handleError('persistDownTimeReasonCodes', []))
  //   );
  // //observables don't get triggered unless there is a subscription.  So force the request by calling subscribe.
  // request.subscribe();

  fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(downtime)
  }).then(function (response) {
    //now that we have the data persisted we need to update the context
    datafetcher.getPersistedDowntimeReasonCodes(customer, archiveDir, contextSetter);
  });

}

export const createDownTimeTotals = (dialogreasoncode, downtimereaons) =>{
  var downtimeTotals = [];
  if (downtimereaons) {
   // this.initDownTimeReaons(downtimereaons);

    //now that we have persisted reason codes and the text for the dialog
    //we can build the content for the stacked bar chart on the dashboard
    //that displays the amount of time specified for each down time reason codes


    dialogreasoncode.forEach(reasoncode => {
      //     console.log("DowntimedialogService reasoncode: " + reasoncode);
      downtimereaons.forEach(downtimereason => {
        setDownTimeTotal(downtimeTotals, downtimereason, reasoncode);

      });
    });
   
    //   console.log("DownTimeDialogsService.primeReasonCodes() completed");
  }
  else {
    console.log("DownTimeDialogsService.primeReasonCodes() no reason codes to prime with");
  }
  return downtimeTotals;
};

const setDownTimeTotal =(downtimeTotals, downtimereason, reasoncode) => {
  // console.log("DownTimeDialogsService.setDownTimeTotal() downtimereason: " + downtimereason + " reasoncode:" + reasoncode);
  if (downtimereason.reasons[0] == reasoncode.id)//we only support 1 reason code per downtime
  {
    var total = downtimeTotals.find(a=>a.name===reasoncode.name);
    if (total)//downtimeTotals.has(reasoncode.name)) {
      {
        total.duration+=downtimereason.duration;
      //var newTotal = downtimeTotals.get(reasoncode.name) + downtimereason.duration;
      //downtimeTotals.set(reasoncode.name, newTotal);
      //  console.log("DownTimeDialogsService.setDownTimeTotal() increasing by " + downtimereason.duration + " to: " + reasoncode.name + " total: " + this.downtimeTotals.get(reasoncode.name));
    }
    else {
      downtimeTotals.push({name: reasoncode.name, duration: downtimereason.duration})
      //downtimeTotals.set(reasoncode.name, downtimereason.duration);
      //  console.log("DownTimeDialogsService.setDownTimeTotal() adding reasoncode.name " + reasoncode.name + " downtimereason.duration: " + downtimereason.duration);
    }
    //  console.log("DownTimeDialogsService.setDownTimeTotal() reasoncode.name: " + reasoncode.name + " total: " + this.downtimeTotals.get(reasoncode.name));

  }
}
