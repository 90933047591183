import React, { useEffect, useState, useContext } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell, LabelList, Text, ResponsiveContainer } from 'recharts'
import { MyContext } from './MyContext';
import * as downtimeUtils from './DowntimeUtils';

export const colors = [
    "#3366CC99",
    "#DC391299",
    "#FF990099",
    "#10961899",
    "#99009999",
    "#3B3EAC99",
    "#0099C699",
    "#DD447799",
    "#66AA0099",
    "#B82E2E99",
    "#31639599",
    "#99449999",
    "#22AA9999",
    "#AAAA1199",
    "#6633CC99",
    "#E6730099",
    "#8B070799",
    "#32926299",
    "#5574A699",
    "#3B3EAC99"
];

const DownTimeTotals = () => {
    const { data, dialogReasonCodes, persistedDowntimeReasonCodes, dowtimeTotals, setDowntimeTotals } = useContext(MyContext);


    useEffect(() => {

        if (persistedDowntimeReasonCodes) {
            var downtimeReasonCodes = downtimeUtils.initDownTimeReaons(persistedDowntimeReasonCodes);
            var sdowntimeTotals = downtimeUtils.createDownTimeTotals(dialogReasonCodes, downtimeReasonCodes);
            if (sdowntimeTotals) {
                //convert these to hours
                sdowntimeTotals.forEach(a => {
                    a.duration /= 60;
                    a.duration = Math.round(a.duration * 10) / 10;
                })
            }
            setDowntimeTotals(sdowntimeTotals);
        }

    }, [persistedDowntimeReasonCodes])
    
    return (
        <div style={{ display: 'flex' }}>
            <ResponsiveContainer width={"100%"} height={500} >
                <BarChart data={dowtimeTotals}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }} >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis label={
                        <Text x={0} y={0} dx={25} dy={300} offset={0} angle={-90} >Machine Down Time - hrs</Text>
                    } />
                    <Tooltip />

                    <Bar dataKey="duration" isAnimationActive={false}>
                        <LabelList dataKey="duration" position="middle" fill="white" />
                        {
                            dowtimeTotals?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index]} />
                            ))
                        }

                    </Bar>

                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default DownTimeTotals;
