import React, { useState, useEffect, useContext, useCallback } from 'react';
import { MyContext } from './MyContext';
import { getObjectPerformanceColor } from './UtilizationGraph';
import Production from './Production';
import UtilizationGraph from './UtilizationGraph';
import MachineTimeline from './MachineTimeline';
import { makeStyles } from '@material-ui/core/styles';
import useInterval from './useInterval';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

//operator specific view
const useStyles = makeStyles({
    mycomponent: {
        background: '#fff'
    },

    fullscreenenabled: {
        '.fullscreen-enabled': {
            background: '#393939'
        }
    }
});

const OperatorView = ({ machineinfo }) => {
    const classes = useStyles();
    const [color, setColor] = useState('#3366cc')
    const [selectThisMachine, setSelectThisMachine] = useState([]);
    const { data, setLastUpdateTime } = useContext(MyContext);
    const [thismachineinfo, setMachineInfo] = useState(machineinfo);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const handle = useFullScreenHandle();
    //this doesn't work b/c the utilization chart will no actively resize so it holds the timeline at a constant location
    //const [marginValue, setMarginValue] = useState(window.innerHeight * -.1);
    useEffect(() => {
        console.log("OperatorView handleResise: window.innerWidth: " + window.innerWidth + " window.innerHeight: " + window.innerHeight);
        window.addEventListener("resize", handleResize);
        return function cleanupListener() {
            window.removeEventListener('resize', handleResize)
        }
    }, []);
    const handleResize = (e) => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
        // var newMarginVal = window.innerHeight * -.1;
        // setMarginValue(newMarginVal)
        console.log("OperatorView handleResise: window.innerWidth: " + window.innerWidth + " window.innerHeight: " + window.innerHeight);// + " newMarginVal: " + newMarginVal);
    };
    useInterval(() => {
        //console.log("OperatorView useEffect customerName: " + customerName);
        setLastUpdateTime(new Date());
    }, 60000);


    useEffect(() => {
        if (machineinfo) {
            //console.log("OperatorView useEffect machineinfo customerName: " + customerName);
            setMachineInfo(machineinfo);
            //setThisCustomerName(customerName);
        }
    }, [machineinfo])

    useEffect(() => {
        if (thismachineinfo) {
            var perfcolor = getObjectPerformanceColor(thismachineinfo.currentuptimepercent, thismachineinfo.averageuptimepercent);
            setColor(perfcolor);
            setSelectThisMachine([thismachineinfo.machinename]);
        }
    }, [thismachineinfo])

    return (
        <div >
            <button style={{ height:'50px' }} onClick={handle.enter}>
                Enter fullscreen
      </button>
            <FullScreen handle={handle} >
                <div style={{ background: "white", height: '100%' }}>
                    <div style={{ background: color, display: 'flex', height: '50px', alignItems: 'center' }}  >
                        <label style={{ color: "white", fontSize: '45px', fontWeight: 'bold', marginLeft: '40%', marginRight: '5px' }}>{thismachineinfo?.machinename}</label>
                        <label style={{ color: "white", fontSize: '45px', fontWeight: 'bold' }}>{thismachineinfo?.partcount > 0 ? 'Part Count: ' + thismachineinfo?.partcount : ""}</label>
                    </div>
                    <div style={{ display: "inline-flex", marginLeft: '1%', marginBottom: '-70', fullscreenenabled: '#393939' }} >
                        <Production data={data} selectedMachines={selectThisMachine} height={windowHeight - 200} width={windowWidth - 300} showXTitle={false} showDots={false} showTopBottom={false} />
                        <UtilizationGraph data={data} selectedMachines={selectThisMachine} height={windowHeight - 150} width={225} pGroupWidth={"9%"} showUIDetails={false} />
                    </div>
                    <div style={{ marginLeft: '2%', marginRight: '2%', alignItems: 'center', marginTop: '0' }}>
                        <MachineTimeline machineNames={selectThisMachine} showSensorView={false} />
                    </div>
                </div>
            </FullScreen>
        </div>
    );
}
export default OperatorView