import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import { MyContext } from './MyContext';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));



export default function MachineList() {
    const { data, selectedMachines, setSelectedMachines } = useContext(MyContext)
    const classes = useStyles();
    // const [checked, setChecked] = React.useState([0]);
    const [selectAllChecked, setSelectAll] = React.useState(true);
    // const { id } = useParams();//this pulls in the url param for the customer
    // useEffect(() => {
    //     console.log("MachineList useEffect id changed: " + id);
    //     setCustomerName(id);

    // }, [id]);
    // useEffect(() => {
    //     console.log("MachineList useEffect data changed: " + data?.customer);
       
       
    // }, [data]);

    
    useEffect(() => {
            setSelectAll(data?.machines.length === selectedMachines.length)
     }, [selectedMachines])

    const handleSelectAll = ()=> () => {
        console.log("MachineList handleSelectAll");
        if (data?.machines) {
            var newChecked = [];
            if (!selectAllChecked) {
                //set all machines selected
                
                if (data?.machines) {
                    data.machines.map((value) => {
                        newChecked.push(value.machinename);
                    });
                   
                }
            }
            setSelectedMachines(newChecked);
            //set all selected to the opposite of the current value
            setSelectAll(!selectAllChecked);
        }
    };

        const handleToggle = (value) => () => {
            const currentIndex = selectedMachines.indexOf(value);
            const newChecked = [...selectedMachines];

            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }

            setSelectedMachines(newChecked);
        };

        return (
            <List className={classes.root}>
                <ListItem key="selectall" dense button onClick={handleSelectAll()}>
                <ListItemIcon>
                <Checkbox
                                    edge="start"
                                    checked={selectAllChecked}
                                    tabIndex={-1}
                                    disableRipple
                                    
                                />
                                </ListItemIcon>
                                <ListItemText id="checkbox-list-label-selectall" primary="Select All" />
                </ListItem>
                {data?.machines.map((value) => {
                    const labelId = `checkbox-list-label-${value.machinename}`;

                    return (
                        <ListItem key={value.machinename} role={undefined} dense button onClick={handleToggle(value.machinename)}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={selectedMachines.indexOf(value.machinename) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.machinename} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="comments">

                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
            </List>
        );
    }
