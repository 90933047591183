import React, { useState, useEffect, Fragment } from 'react';
import { ComposedChart, Legend, Line, BarChart, Bar, ResponsiveContainer, LabelList, Brush, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import useUtilizationData from './useUtilizationData';
import useGraphLegend from './useGraphLegend';
import Checkbox from '@material-ui/core/Checkbox';

const AnalyticsUtilizationByShift = () => {
    const [totalHours, setTotal] = useState([]);
    const { avgOneByDate, avgTwoByDate, avgThreeByDate } = useUtilizationData();
    const { showHideBar, handleLegendClick } = useGraphLegend(); //this contains the bars that are hidden
    const [showLineChart, setShowLineChart] = useState(false);

    useEffect(() => {
        var hasThreeShifts = false;
        if (avgThreeByDate != null) {
            hasThreeShifts = true;
        }
        //consolidate the shift data into 1 array with elements that have a value for each shift
        const totalHrs = [];
        for (var i = 0; i < avgOneByDate.length; i++) {
            if (hasThreeShifts) {
                totalHrs.push({ date: avgOneByDate[i].date, shift1: avgOneByDate[i].avg, shift2: avgTwoByDate[i].avg, shift3: avgThreeByDate[i].avg })
            }
            else {
                totalHrs.push({ date: avgOneByDate[i].date, shift1: avgOneByDate[i].avg, shift2: avgTwoByDate[i].avg })
            }
        }
        setTotal(totalHrs);

    }, [avgOneByDate, avgTwoByDate, avgThreeByDate]);

    return (
        <div>
            <div style={{ marginTop: '2px', marginLeft: '1px', display: 'flex' }}>
                <h4>Monthly Utilization by Shift</h4>
                <div style={{ position: 'absolute', right: '0px' }}>
                    Line Chart<Checkbox style={{ width: 30 }} checked={showLineChart} onChange={() => { setShowLineChart(!showLineChart) }} />
                </div>
            </div>
            <ResponsiveContainer width={"100%"} height={700} >
                <ComposedChart
                    data={totalHours}

                    margin={{
                        top: 10, right: 30, left: 0, bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip offset={30} />
                    <Legend onClick={handleLegendClick} />
                    {showLineChart ?
                        <Line type="monotone" dataKey="shift1" name="Shift 1" hide={showHideBar.indexOf('shift1') !== -1} stroke="#8884d8" fill="#8884d8" />
                        :
                        <Bar type="monotone" dataKey="shift1" name="Shift 1" hide={showHideBar.indexOf('shift1') !== -1} stroke="#8884d8" fill="#8884d8" />
                    }
                    {showLineChart ?
                        <Line type="monotone" dataKey="shift2" name="Shift 2" hide={showHideBar.indexOf('shift2') !== -1} stroke="#4442d2" fill="#4442d2" />
                        :
                        <Bar type="monotone" dataKey="shift2" name="Shift 2" hide={showHideBar.indexOf('shift2') !== -1} stroke="#4442d2" fill="#4442d2" />
                    }

                    {avgThreeByDate ?
                        showLineChart ?
                            <Line type="monotone" name="Shift 3" dataKey="shift3" hide={showHideBar.indexOf('shift3') !== -1} stroke="#00ffd2" fill="#00ffd2" />
                            :
                            <Bar type="monotone" name="Shift 3" dataKey="shift3" hide={showHideBar.indexOf('shift3') !== -1} stroke="#00ffd2" fill="#00ffd2" />
                        : <div></div>}
                    <Brush />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};
export default AnalyticsUtilizationByShift;