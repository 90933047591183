import React, {useContext,useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import { MyContext } from './MyContext';
import DoneIcon from '@material-ui/icons/Done';

import { SettingsPowerRounded } from '@material-ui/icons';

// const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  root: {
    '&$selected': {
      backgroundColor: '#dc3912',
      '&:hover': {
        backgroundColor: '#dc3912',
      }
    },
  },
  selected: {},
});

const DowntimeDialog = ({ onClose, selectedValue, open, setOpen }) => {
  const classes = useStyles();
  const { data, dialogReasonCodes,  persistedDowntimeReasonCodes } = useContext(MyContext);
  const [userSelectedValue, setUserSelectedValue] = useState();
//   const { onClose, selectedValue, open } = props;

useEffect(() => {
 setUserSelectedValue(selectedValue);
}, [selectedValue]);
  const handleClose = () => {
    onClose(userSelectedValue);
  };

  const handleListItemClick = (value) => {
    if (value.name !== userSelectedValue.reasoncode)
    {
    setUserSelectedValue({reasoncode: value.name, reasontext: userSelectedValue.reasontext, reasonid: value.id})
    }
    else
    {
      //clear the selection
      setUserSelectedValue({reasoncode:null, reasontext:  userSelectedValue.reasontext, reasonid: null});
    }
   // onClose(value);
  };
  const handleTextChange = (e) => {
     setUserSelectedValue({reasoncode: userSelectedValue.reasoncode, reasontext:e.target.value, reasonid: userSelectedValue.reasonid})
   // onClose(value);
  };

  return (
    // 12/7/2020 added disablePortal b/c in operatorView fullscreen the dialog would be behind the screen
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} disablePortal> 
      <DialogTitle id="simple-dialog-title">Select Down Time Reason Code</DialogTitle>
      <List>
        {dialogReasonCodes?.map((reasonCode) => (
          <ListItem button onClick={() => handleListItemClick(reasonCode)} key={reasonCode.id} classes={{ root: classes.root, selected: classes.selected }} selected={reasonCode.name==userSelectedValue?.reasoncode}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <DoneIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={reasonCode.name} />
          </ListItem >
        ))}

        
      </List>
      <TextField
            // autoFocus
            margin="dense"
            id="reasontext"
            label="Optional Text"
            fullWidth
            onChange={handleTextChange}
            value={userSelectedValue?.reasontext}

          />
      <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
          <Button onClick={()=>{setOpen(false)}} color="primary">
            Cancel
          </Button>
        </DialogActions>
    </Dialog>
  );
}

DowntimeDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.object.isRequired,
};

export default DowntimeDialog;
