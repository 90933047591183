import React, { useState, useEffect, useContext } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Label } from 'recharts';
import { MyContext } from './MyContext';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

export const handleSetSelectedMachines = (data, selectedMachines, setSelectedMachines, showTargets) => {
    if (data?.machines) {
        var newChecked = [];
        data.machines.map((value) => {
            if (showTargets) {//maintain show targets
                //console.log("value.targetuptimeminutes: " + value.targetuptimeminutes);
                if (value.targetuptimeminutes > 0) {
                    newChecked.push(value.machinename);
                }
            }
            else if (selectedMachines.length > 0 && selectedMachines.length < data.machines.length) {
                //this is the case the user selected specific machines and the data is changing
                //we want to maintain that selection otherwise it'll get reset
                if (selectedMachines.includes(value.machinename)) {
                    newChecked.push(value.machinename);
                }
            }
            else {
                newChecked.push(value.machinename);
            }
        });
        setSelectedMachines(newChecked);
        //console.log("ShowTargets useEffect data update: " + showTargets + " newChecked.length: " + newChecked.length);
    }
};
export const handleSetSelectedMachinesForShopFloorDisplay = (data, selectedMachines, setSelectedMachines) => {
    if (data?.machines) {
        var newChecked = [];
        data.machines.map((value) => {
            if (value.customersethide) {
                return null;
            }
            else {
                newChecked.push(value.machinename);
            }
        });
        setSelectedMachines(newChecked);
        //console.log("ShowTargets useEffect data update: " + showTargets + " newChecked.length: " + newChecked.length);
    }
};


const ShowTargets = () => {
    const { data, selectedMachines, setSelectedMachines, } = useContext(MyContext);
    const [showTargets, setShowTargets] = useState(false);

    //This will handle the selection list when the data changes and wether to maintain the 
    //target selection
    useEffect(() => {
        if (data){
        handleSetSelectedMachines(data, selectedMachines, setSelectedMachines, showTargets);
        }
    }, [data])

    useEffect(() => {
        //  console.log("ShowTargets useEffect selectedMachines update: " + showTargets);
        //we want to know when the user is making machine selection changes and 
        //if those selections are aligned w/ the targets.
        if (data?.machines) {
            //build up a new array of target machines.
            var newChecked = [];
            data.machines.map((value) => {
                if (value.targetuptimeminutes > 0) {
                    newChecked.push(value.machinename);
                }
            });
            //test if the new array contains the same elements as the selectedMachines
            setShowTargets(newChecked.length == selectedMachines.length && newChecked.every((val, index) => selectedMachines.indexOf(val) != -1));
        }
    }, [selectedMachines])

    const handleChange = () => () => {
        console.log("MachineList handleSelectAll");
        if (data?.machines) {
            var newChecked = [];
            if (!showTargets) {
                //set all machines selected
                if (data?.machines) {
                    data.machines.map((value) => {
                        if (value.targetuptimeminutes > 0) {
                            newChecked.push(value.machinename);
                        }
                    });

                }
            }
            else {
                data.machines.map((value) => {

                    newChecked.push(value.machinename);
                }
                );
            }
            setSelectedMachines(newChecked);
            //set all selected to the opposite of the current value
            setShowTargets(!showTargets);
        }
    };
    return (
        <div style={{ float: 'right', paddingLeft: 14 }}>
                Show Targets
                <Tooltip TransitionComponent={Zoom} title="Are you an advanced MUT user and set targets?  Select this to quickly view the machines with targets set.">
            <Checkbox
                    edge="start"
                    checked={showTargets}
                    onChange={handleChange()}
                    tabIndex={-1}
                    disableRipple

                />
            </Tooltip>
        </div>
    );
}
export default ShowTargets;

