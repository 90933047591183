import { useState, useContext, useEffect } from 'react';
import { MyContext } from './MyContext';


//this is very similiar to useUptimeData with the addition of creating  a collection for the machines totals within the month/shift
const useTopMachineData = () => {
    const { uptimeData, selectedMachines } = useContext(MyContext)
    const [sumOneByDate, setSumOneByDate] = useState([])
    const [sumOneByMachine, setSumOneByMachine] = useState([]);
    const [sumTwoByDate, setSumTwoByDate] = useState([])
    const [sumThreeByDate, setSumThreeByDate] = useState(null)
    const [avgDailyUptime, setAvgDailyUptime] = useState([]);

    useEffect(() => {

        if (uptimeData) {
            //This will create 3 collections.  1 for each shift
            //and sum the daily uptime across machines.
            const sumOneDate = [];
            const sumTwoDate = [];
            const sumThreeDate = [];
            sumShiftByDate(sumOneDate, "s1hours");
            sumShiftByDate(sumTwoDate, "s2hours");
            // var hasThreeShifts = false;
            if (uptimeData[0]["s3hours"] != null) {
                // hasThreeShifts = true;
                sumShiftByDate(sumThreeDate, "s3hours");
                setSumThreeByDate(sumThreeDate);
            }
            setSumOneByDate(sumOneDate);
            setSumTwoByDate(sumTwoDate);

        }

    }, [uptimeData, selectedMachines]);

    const sumShiftByDate = (sumDate, shiftProp) => {

        //get distinct dates
        const uniqueDates = [];
        for (let value of uptimeData) {

            //break this appart from "10/01/20" "month/year"
            var parts = value.date.split('/');
            var monthYear = parts[0] + "/" + parts[2];
            if (uniqueDates.indexOf(monthYear) === -1) {
                uniqueDates.push(monthYear);
            }

        }

        const sumReducer = (accumulator, currentValue) => accumulator + currentValue;
        uniqueDates.forEach((aDate) => {
            let filterByDate = uptimeData.filter(a => {
                //Needs to take into account the machine selection
                //The issue w/ this is machines that we have monitored in the past but no longer do
                //will not be included in these totals...ugh
                var result = false;
                if (selectedMachines.indexOf(a.machinename) !== -1) {
                    var parts = a.date.split('/');
                    var monthYear = parts[0] + "/" + parts[2];
                    result = monthYear === aDate
                    return result
                }
                return result;
            });
            let hours = filterByDate.map(a => a[shiftProp]);
            let sum = 0;
            if (hours.length > 0) {
                sum = hours.reduce(sumReducer);
            }
            //round to 0 decimal places
            sum = Math.round(sum);
            //we just found the sum of hours for the shift passed  in to the function for the month/year.
            //now we want to know how each machine contributed to that sum for this 

            let MachineNames = filterByDate.map(a => a.machinename);
            //create a new machine name array of distinct names
            MachineNames = [...new Set(MachineNames)];
            const machineTotals = [];
            MachineNames.forEach((amachine) => {
                var machineDays = filterByDate.filter(a => a.machinename === amachine);
                var machineMonthlyHours = machineDays.map(a => a[shiftProp]);
                var machineMonthlyHoursTotal = machineMonthlyHours.reduce(sumReducer);
                machineMonthlyHoursTotal = Math.round(machineMonthlyHoursTotal );
                machineTotals.push({ machinename: amachine, total: machineMonthlyHoursTotal });
                //console.log("date " + aDate + " machine: " + amachine + " total Hours: " + machineMonthlyHoursTotal);
            });
            
            machineTotals.sort(function (a,b){return (a.total < b.total ? 1 : -1)});  //This is the way it needs to be done for it to work in Chrome (a.total<b.total) by itself only works in firefox.
           // console.log("useTopMachineData sum hours for date: " + aDate + " " + sum + " machineTotals.length: " + machineTotals.length);
            sumDate.push({ date: aDate, sum: sum, machinetotals: machineTotals });

        });

    };

    return {
        sumOneByDate,
        sumTwoByDate,
        sumThreeByDate,
        avgDailyUptime
    };


};
export default useTopMachineData;