import React, { useState, useEffect, useContext } from 'react';
import { ResponsiveContainer, LineChart, Line, Brush, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import useUptimeData from './useUptimeData';

const UptimeAnalyticsDailyAvg = () => {
    const { avgDailyUptime } = useUptimeData();
    
    const [minDailyAvg, setMinDailyAvg] = useState(0);
    const [maxDailyAvg, setMaxDailyAvg] = useState(0);
    useEffect(() => {

        if (avgDailyUptime && avgDailyUptime.length>0) {
            //This will create 3 collections.  1 for each shift
            //and sum the daily uptime across machines.
           
            var values = avgDailyUptime.map(a=>a.avg);
            var minAvg = Math.min(...values);
            var maxAvg = Math.max(...values);
            console.log("min value: " + minAvg + " max value: " + maxAvg);
            //add a little space for the display
            minAvg*=.8;
            maxAvg*=1.2;
            //round
            minAvg = Math.round(minAvg);
            maxAvg = Math.round(maxAvg);
            console.log("min value: " + minAvg + " max value: " + maxAvg);
            setMinDailyAvg(minAvg);
            setMaxDailyAvg(maxAvg);
         }

    }, [avgDailyUptime]);

    
    return (
        <div  >
            <h4>Average Hours/Day of Uptime</h4>
            <ResponsiveContainer width={"100%"} height={250} >
                <LineChart
                    data={avgDailyUptime}
                    syncId="anyId"
                    margin={{
                        top: 0, right: 30, left: 0, bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis domain={[minDailyAvg, maxDailyAvg]}/>
                    <Tooltip />
                    <Line type="monotone" dataKey="avg" stroke="#8884d8" fill="#8884d8" />
                    <Brush />
                </LineChart>
            </ResponsiveContainer>

        </div>
    );
};
export default UptimeAnalyticsDailyAvg;